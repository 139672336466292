import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import queryClient from "config/queyClient.config";
import {
  upgradeAgent,
  USER_LISTS,
} from "queries/management/userManagement.queries";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Ranks } from "utils/getRank";

interface ModalProps {
  id: string;
  availableRanks: Ranks[];
  [index: string]: any;
}

export default NiceModal.create(({ id, availableRanks }: ModalProps) => {
  const modal = useModal();
  const [selectedRank, setSelectedRank] = useState("");
  const UpgradeAgentRankMutation = useMutation<any, Error, any>(upgradeAgent, {
    onSuccess: () => {
      toast.success("Rank updated successfully");
      modal.remove();
      queryClient.invalidateQueries(USER_LISTS);
    },
  });

  const handleUpgrade = () => {
    UpgradeAgentRankMutation.mutate({ userId: id, rank: selectedRank });
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <Typography
          sx={{ mb: 2 }}
          color="primary"
          fontSize={16}
          fontWeight={500}
        >
          Rank Upgrade
        </Typography>

        <div className="h-5"></div>
        <div>
          <Select
            defaultValue=""
            displayEmpty
            fullWidth
            onChange={(e) => setSelectedRank(e.target.value)}
          >
            <MenuItem disabled value="">
              Select Rank
            </MenuItem>
            {availableRanks?.map((rank) => (
              <MenuItem key={rank} value={rank}>
                {rank}
              </MenuItem>
            ))}
          </Select>

          <div className="h-6"></div>
          {UpgradeAgentRankMutation.error && (
            <Alert severity="error">
              {UpgradeAgentRankMutation.error.message}
            </Alert>
          )}
          <LoadingButton
            loading={UpgradeAgentRankMutation.isLoading}
            disabled={UpgradeAgentRankMutation.isLoading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleUpgrade}
          >
            Upgrade
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
});
