import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { useTransactionHistory } from "queries/transaction-history";
import React from "react";
import TransactionTable from "routes/transaction/components/TransactionTable";
import Overviews from "./components/overviews";

interface Props {}
const UserDashbaord = (props: Props) => {
  const { data, isLoading, error } = useTransactionHistory({
    page: 1,
  });

  return (
    <>
      <SectionTitle.Default title="Dashboard" />
      <SpaceBar.SectionGap />
      <Overviews />
      <SpaceBar.SectionGap />
      <SectionTitle.Default title="Recent Transactions" />
      <TransactionTable
        docs={data?.docs || []}
        isLoading={isLoading}
        error={error?.message}
      />
    </>
  );
};

export default UserDashbaord;
