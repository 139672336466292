import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@mui/material";
import React from "react";
import OtpInput from "react-otp-input";
import { UseMutationResult } from "react-query";

interface Props {
  value: string;
  onChange: Function;
  resendMutation: UseMutationResult<any, Error, any>;
  traceId?: string;
}

const TwoFaForm = ({ value, onChange, resendMutation, traceId }: Props) => {
  const { isLoading, error, mutate } = resendMutation;

  return (
    <form className="flex flex-col gap-y-6">
      {error && <Alert severity="error">{error.message}</Alert>}
      <div>
        <OtpInput
          value={value}
          onChange={onChange}
          numInputs={6}
          separator={
            <span>
              <div className="mx-1" />
            </span>
          }
          containerStyle="w-full h-full items-stretch justify-center"
          inputStyle={{
            width: "2.2rem",
            height: "3rem",
            fontSize: "1.2rem",
            borderRadius: 6,
            border: "1px solid black",
            background: "#F2F2F2",
          }}
          focusStyle={{
            border: "2px solid #0DAF1D",
          }}
        />
      </div>
      <div>
        <p>
          Didn’t receive the OTP?{" "}
          <LoadingButton
            variant="text"
            loading={isLoading}
            onClick={() => mutate(traceId || "")}
          >
            Resend Otp
          </LoadingButton>
        </p>
      </div>
    </form>
  );
};

export default TwoFaForm;
