import RequireAuth from "components/auth/RequireAuth";
import { RouteGroups, RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import ForgotPassword from "routes/auth/forgot_password";
import ResetPassword from "routes/auth/reset_password";
import Login from "routes/Login";
import PageNotFound from "routes/PageNotFound";
import UserDashbaord from "routes/user/dashboard";
import UserManagement from "routes/user/management";
import AdminManagement from "./admin-management";
import AgentManagement from "./agent/agent_management";
import GameManagement from "./game-management";
import GameReports from "./game-management/gameReports";
import GCRequests from "./gc-management/GCRequests";
import GCTransfer from "./gc-management/GCTransfer";
import AgentCommissionPage from "./reports/agent_commission";
import PlayerBalancePage from "./reports/player_balance";
import TransactionHistory from "./transaction/TransactionHistory";
import UserProfilePage from "./user/profile";
import DisputeDetail from "./withdrawal-management/DisputeDetail";
import History from "./withdrawal-management/History";
import ResolutionList from "./withdrawal-management/ResolutionList";

interface Props {}

const privateRoute: RouteObject[] = [
  {
    path: "/",
    children: [
      { index: true, element: <Navigate to={RouteGroups.USER.link} /> },
      {
        path: RouteGroups.USER.link,
        element: <RequireAuth />,
        children: [
          { index: true, element: <Navigate to={RouteLinks.DASHBOARD.link} /> },
          { path: RouteLinks.DASHBOARD.link, element: <UserDashbaord /> },
          {
            path: RouteLinks.USER_MANAGEMENT.link,
            element: <UserManagement />,
          },
          {
            path: "gc",
            children: [
              {
                path: "transfer",
                element: <GCTransfer />,
              },
              {
                path: "requests",
                element: <GCRequests />,
              },
            ],
          },
          {
            path: "reports",
            children: [
              {
                path: RouteLinks.AGENT_COMMISSION.link,
                element: <AgentCommissionPage />,
              },
              {
                path: RouteLinks.PLAYER_BALANCE.link,
                element: <PlayerBalancePage />,
              },
            ],
          },
          {
            path: RouteLinks.PROFILE.link,
            element: <UserProfilePage />,
          },
          {
            path: "transaction-history",
            element: <TransactionHistory />,
          },
          {
            path: "agent-management",
            element: <AgentManagement />,
          },
          {
            path: "game",
            children: [
              {
                path: "manage",
                element: <GameManagement />,
              },
              {
                path: "reports",
                element: <GameReports />,
              },
            ],
          },
          {
            path: "withdraw",
            children: [
              {
                path: "history",
                element: <History />,
              },
              {
                path: "resolution",
                children: [
                  {
                    index: true,
                    element: <ResolutionList />,
                  },
                  {
                    path: ":id",
                    element: <DisputeDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: "admin-management",
            element: <AdminManagement />,
          },
        ],
      },
      { path: RouteLinks.LOGIN.link, element: <Navigate to="/" /> },
      { path: "*", element: <PageNotFound /> },
    ],
  },
];

const publicRoute: RouteObject[] = [
  { path: RouteLinks.LOGIN.link, element: <Login /> },
  { path: RouteLinks.FORGOT_PASSWORD.link, element: <ForgotPassword /> },
  {
    path: RouteLinks.RESET_PASSWORD.link + "/:token",
    element: <ResetPassword />,
  },
  { path: "*", element: <Navigate to={RouteLinks.LOGIN.link} replace /> },
];

const Routes = (props: Props) => {
  const auth = useContext(AuthContext);

  const [routes, setRoutes] = useState<RouteObject[]>(
    auth.token ? privateRoute : publicRoute
  );

  // change routes on token state
  useEffect(() => {
    setRoutes(auth.token ? privateRoute : publicRoute);
  }, [auth.token]);

  const appRoutes = useRoutes(routes);

  if (auth.token) {
    return <div>{appRoutes}</div>;
  }

  return <div>{appRoutes}</div>;
};

export default Routes;
