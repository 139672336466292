import { Theme } from "@mui/material";

const responsiveStyle = (
  theme: Theme,
  largeSize: number,
  smallSize: number
) => {
  return {
    [theme.breakpoints.down("md")]: {
      fontSize: smallSize,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: largeSize,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: smallSize,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: largeSize,
    },
  };
};

export default responsiveStyle;
