import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import avatar from "assets/icons/avatar.png";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import LanguageSwitcher from "language/LangSwitcher";
import React, { FC } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BackButtonLinkType } from "./Drawer";

interface AppBarProps {
  handleDrawerToggle: () => void;
  backButtonLink: BackButtonLinkType;
}

const AppBar: FC<AppBarProps> = ({ handleDrawerToggle, backButtonLink }) => {
  const { logout, user } = React.useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <MuiAppBar position="sticky" elevation={4}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { lg: "none" } }}
        >
          <AiOutlineMenu color="#000" />
        </IconButton>
        {backButtonLink && (
          <IconButton
            component={Link}
            to={"backButtonLink"}
            sx={{
              borderRadius: 1,
              mr: "auto",
              "&:hover": { bgcolor: "transparent" },
            }}
          >
            <HiOutlineArrowSmLeft />
            <span className="ml-2 text-d-black font-semibold text-sm">
              Back
            </span>
          </IconButton>
        )}
        <LanguageSwitcher />
        <Box sx={{ flexGrow: 0, marginLeft: 1 }}>
          <IconButton sx={{ p: 0, mr: 3 }}>
            <IoNotificationsOutline />
          </IconButton>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src={user?.data?.photo ?? avatar} />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            classes={{ root: "dropdownRootStyle" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <Link to={RouteLinks.PROFILE.link}>Profile</Link>
            </MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
