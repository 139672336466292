import { Button, TableCell, TableRow } from "@mui/material";
import CustomTable from "components/table/Table";
import { IWithdrawHistoryResponse } from "queries/transaction-history";
import { Link } from "react-router-dom";
import { getDateFormated } from "utils";

type ITransactionTableProps = {
  isLoading?: boolean;
  error?: string;
  docs: IWithdrawHistoryResponse[];
};

const WithdrawTransactionTable = ({
  docs,
  isLoading,
  error,
}: ITransactionTableProps) => {
  return (
    <CustomTable
      columns={[
        "Timestamp",
        "TXID",
        "From User",
        "To User",
        "Amount",
        // "Status",
        "Action",
      ]}
      isLoading={isLoading}
      error={error}
    >
      {docs?.map((row) => (
        <TableRow key={row._id}>
          <TableCell>{getDateFormated(row.createdAt)}</TableCell>
          <TableCell>{row.txid}</TableCell>
          <TableCell>{row.user.username}</TableCell>
          <TableCell>{row?.issuer?.username}</TableCell>
          <TableCell>{row.equivalentAmount?.toFixed(2)} PHP</TableCell>
          {/* <TableCell>
            {<Chip label={row.status} color={statusColors[row.status]} />}
          </TableCell> */}
          <TableCell>
            <Link to={`/user/withdraw/resolution/${row._id}`}>
              <Button variant="outlined" color="inherit">
                View
              </Button>
            </Link>
          </TableCell>
        </TableRow>
      ))}
      {docs.length === 0 && (
        <p className="text-yellow-600 p-3 text-lg">No Data Found.</p>
      )}
    </CustomTable>
  );
};

export default WithdrawTransactionTable;
