import _ from "lodash";
import moment from "moment";
import { RankStatus } from "queries/rank";

type IColorSchemaProps =
  | "default"
  | "error"
  | "info"
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | undefined;

type ValueType = RankStatus;

const successTtype: ValueType[] = ["approved"];
const warningTtype: ValueType[] = ["pending"];
const errorTtype: ValueType[] = ["declined"];

export const getColorSchema = (value: ValueType): IColorSchemaProps => {
  if (successTtype.includes(value)) {
    return "success";
  } else if (warningTtype.includes(value)) {
    return "warning";
  } else if (errorTtype.includes(value)) {
    return "error";
  }
  return "default";
};

export const getDateFormated = (
  date: Date | undefined | string,
  format: string | undefined = "MM-DD-YYYY HH:mm:ss"
) => {
  if (date) {
    return moment.utc(date).format(format);
  }
  return "";
};

enum twoDecimalCoinEnum {
  usdc = "usdc",
  usdt = "usdt",
}

export function getDecimalOnCoin(coin: string) {
  if (!coin) return 0;
  return coin?.toLowerCase() === twoDecimalCoinEnum?.usdc ||
    coin.toLowerCase() === twoDecimalCoinEnum?.usdt
    ? 2
    : 8;
}

export const formatFilters = (filters: Record<string, any>) => {
  const normalizedFilters = _.cloneDeep(filters);
  Object.keys(normalizedFilters).forEach((key) => {
    if (moment.isMoment(normalizedFilters[key])) {
      normalizedFilters[key] = moment(normalizedFilters[key])
        .utcOffset(0, true)
        .format("YYYY-MM-DD");
      return;
    }
    if (normalizedFilters[key] === "none") {
      normalizedFilters[key] = null;
      return;
    }
    return normalizedFilters[key];
  });
  return normalizedFilters;
};
