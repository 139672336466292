import { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import RankUpgradeModal from "components/modals/RankUpgradeModal";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import { isEmpty } from "lodash";
import { useDropdownFilter } from "queries/filter";
import { useUserList } from "queries/management/userManagement.queries";
import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { MdUpgrade } from "react-icons/md";
import { ROWS_PER_PAGE } from "routes/user/management";
import { getDateFormated } from "utils";
import { getRank, Ranks } from "utils/getRank";

const AgentManagementPage = () => {
  const UpgradeModal = useModal(RankUpgradeModal);

  const [page, setPage] = usePaging();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [rank, setRank] = useState("none");
  const [searchKey, debouncedSearchKey, setDebounsedSearchKey] =
    useDebounceSearch();

  const { isLoading, error, data, isRefetching } = useUserList({
    search: debouncedSearchKey,
    page: page + 1,
    rank: rank,
    fromDate,
    toDate,
    role: "agent",
  });
  const dowpdownFilters = useDropdownFilter("rank");

  const handleUpgradeRank = (userId: string, availableRanks: Ranks[]) => {
    UpgradeModal.show({ id: userId, availableRanks: availableRanks });
  };

  return (
    <>
      <SectionTitle.Default title="Agent Management">
        <div className="flex gap-4 flex-wrap">
          <FormControl>
            <p className="text-xs text-gray-200">From</p>
            <DatePicker
              value={fromDate}
              onChange={(value: Date | null) => {
                setFromDate(value);
              }}
            />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">To</p>
            <DatePicker value={toDate} onChange={(value) => setToDate(value)} />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">By Rank</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rank}
              label="Age"
              onChange={(e) => setRank(e.target.value)}
              disabled={dowpdownFilters.isLoading}
            >
              <MenuItem value={"none"}>All Rank</MenuItem>
              {dowpdownFilters.data?.rank?.map((rank) => (
                <MenuItem value={rank} key={rank}>
                  {rank}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <p className="text-xs text-gray-200">Search</p>
            <TextField
              onChange={setDebounsedSearchKey}
              disabled={isLoading}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <IconButton>
                    <BiSearch />
                  </IconButton>
                ),
              }}
              placeholder="Search"
              value={searchKey}
            />
          </div>
        </div>
      </SectionTitle.Default>

      <div className="h-4" />

      <CustomTable
        columns={["Joined", "Name", "Username", "Rank", "Action"]}
        isLoading={isLoading || isRefetching}
        skeletonLength={10}
        error={error && "error occured"}
      >
        {data?.docs.map((user) => (
          <TableRow key={user._id}>
            <TableCell>
              {getDateFormated(user.createdAt, "YYYY-MM-DD HH:mm")}
            </TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                {getRank(user.rank) !== undefined && (
                  <img
                    src={getRank(user.rank)}
                    alt={`rank-icon-${user.rank}`}
                    loading="lazy"
                    className="w-10 hover:scale-150"
                  />
                )}
                <Chip
                  label={user.rank || "player"}
                  color="info"
                  sx={{ textTransform: "uppercase" }}
                />
              </div>
            </TableCell>
            <TableCell>
              {/* allow upgrade if user is not at highest rank */}
              {!isEmpty(user.upgradableRanks) && (
                <Button
                  variant="outlined"
                  startIcon={<MdUpgrade />}
                  size="small"
                  onClick={() =>
                    handleUpgradeRank(user._id, user.upgradableRanks)
                  }
                >
                  Upgrade
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
        {data?.docs.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No user Found.</p>
        )}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={setPage}
        hidden={isLoading}
      />
    </>
  );
};

export default AgentManagementPage;

export const AgentActions = () => {
  const canBlock = Math.random() < 0.5;
  const blocked = Math.random() < 0.5;
  return (
    <Chip
      clickable={canBlock || blocked}
      sx={{ minWidth: "96px" }}
      label={blocked ? "Unblock" : "Block"}
      color={blocked ? "success" : canBlock ? "error" : "default"}
    />
  );
};
