import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useChangePasswordMutation } from "queries/auth";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

const schema = yup.object({
  oldPassword: yup.string().required("Required"),
  newPassword: yup.string().required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords don't match.")
    .required("Required"),
});
export type IChangePasswordForm = yup.InferType<typeof schema>;

interface IProps {
  email: string;
  [index: string]: any;
}

const ChangePasswordModal = NiceModal.create<IProps>(() => {
  const modal = useModal();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(schema),
  });

  const ChangePasswordMutation = useChangePasswordMutation({
    onError: (msg) => {
      toast.error(msg);
    },
    onSuccess: () => {
      modal.remove();
      toast.success("Password updated successfully");
    },
  });

  const onSubmit: SubmitHandler<IChangePasswordForm> = (data) => {
    console.log("submitting", data);
    ChangePasswordMutation.mutate(data);
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <form
          className="flex flex-col gap-y-4 w-72"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <p className="mb-2">Current Password</p>
            <Controller
              name="oldPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  error={Boolean(errors.oldPassword)}
                  helperText={errors.oldPassword?.message}
                  type="password"
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <p className="mb-2">New Password</p>
            <Controller
              name="newPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword?.message}
                  type="password"
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <p className="mb-2">Re-type new Password</p>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  type="password"
                  {...field}
                />
              )}
            />
          </div>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            loading={ChangePasswordMutation.isLoading}
            disabled={ChangePasswordMutation.isLoading}
          >
            Update
          </LoadingButton>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
});

export default ChangePasswordModal;
