import { IconButton, TableCell, TableRow, TextField } from "@mui/material";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import useDebounceSearch from "hooks/useDebounceSearch";
import { useInfiniteGameReports } from "queries/games";
import { BiSearch } from "react-icons/bi";

type Props = {};

const GameReports = (props: Props) => {
  const [searchValue, debouncedSearch, setSearchValue] = useDebounceSearch();
  console.log(debouncedSearch, "debouncedSearch");

  /* 
    Game reports fetch hook
  */
  const { isLoading, error, data, isRefetching } = useInfiniteGameReports({
    search: debouncedSearch,
  });

  return (
    <div>
      <SectionTitle.Default title="Game Reports">
        <div className="flex gap-4 flex-wrap">
          <TextField
            onChange={setSearchValue}
            // disabled={isLoading}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <IconButton>
                  <BiSearch />
                </IconButton>
              ),
            }}
            placeholder="Search"
            value={searchValue}
          />
        </div>
      </SectionTitle.Default>

      <CustomTable
        columns={["Games", "Gross Turnover", "Active Players", "PT Win/Loss"]}
        isLoading={isLoading || isRefetching}
        skeletonLength={10}
        error={error && "error occured"}
      >
        {data?.docs?.map((report) => (
          <TableRow key={report?._id}>
            <TableCell>{report?.name}</TableCell>
            <TableCell>{report?.grossTurnOver?.toFixed(2)}</TableCell>
            <TableCell>{report?.userCount}</TableCell>
            <TableCell>
              {report?.pnl && (
                <span
                  className={
                    report?.pnl >= 0 ? "text-green-500" : "text-red-500"
                  }
                >
                  {report?.pnl >= 0 ? "+" : ""}
                  {report?.pnl?.toFixed(2)}
                </span>
              )}
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
          <TableCell sx={{ fontWeight: 600 }}>Total</TableCell>
          <TableCell sx={{ fontWeight: 600 }}>
            {data?.totalTurnOver?.toFixed(2)}
          </TableCell>
          <TableCell sx={{ fontWeight: 600 }}>
            {data?.totalUserCount ?? 0}
          </TableCell>
          <TableCell sx={{ fontWeight: 600 }}>
            {data?.totalPnl && (
              <span
                className={
                  data?.totalPnl >= 0 ? "text-green-500" : "text-red-500"
                }
              >
                {data?.totalPnl >= 0 ? "+" : ""}
                {data?.totalPnl?.toFixed(2)}
              </span>
            )}
          </TableCell>
        </TableRow>

        {data?.docs.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No Data Found.</p>
        )}
      </CustomTable>
    </div>
  );
};

export default GameReports;
