import { Alert, Box, Collapse, IconButton } from "@mui/material";
import Logo from "assets/icons/logo-dark.png";
import LoginForm from "components/form/LoginForm";
import queryString from "query-string";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {}

interface IParsedSearch {
  verified?: string;
}

const Login = (props: Props) => {
  console.log("login");
  const { search } = useLocation();
  let navigate = useNavigate();

  const parsed: IParsedSearch = queryString.parse(search);
  const [open, setOpen] = useState(true);
  const closeNotification = () => {
    setOpen(false);
    navigate("/login");
  };

  return (
    <div className="p-3 lg:min-h-screen bg-primary-background w-full flex items-center justify-center">
      <div className="px-4 lg:px-12 xl:px-36 py-4 lg:pt-12 xl:pt-16 lg:pb-12 xl:pb-20 bg-black rounded-2.5xl w-full md:w-3/6 xl:w-[842px]">
        <div className="mb-10 lg:mb-16 text-center">
          <img
            className="inline-block mb-12 mt-3"
            width={150}
            src={Logo}
            alt="logo"
          />
          <h1 className="text-xl md:text-2xl xl:text-3xl text-white font-semibold">
            Sign in to kryptobet Admin
          </h1>
        </div>
        {parsed?.verified === "true" && (
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                sx={{ mb: 4 }}
                severity="success"
                color="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closeNotification}
                  >
                    <IoMdClose />
                  </IconButton>
                }
              >
                Email Verification Is Successfull
              </Alert>
            </Collapse>
          </Box>
        )}
        {parsed?.verified === "false" && (
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                sx={{ mb: 4 }}
                severity="error"
                color="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closeNotification}
                  >
                    <IoMdClose />
                  </IconButton>
                }
              >
                Email Verification Is Not Successfull
              </Alert>
            </Collapse>
          </Box>
        )}
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
