import React from "react";
import ProfileCard from "./components/ProfileCard";
import SecurityCard from "./components/SecurityCard";

const UserProfilePage = () => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-1 xl:grid-cols-2">
      <ProfileCard />
      <SecurityCard />
    </div>
  );
};

export default UserProfilePage;
