import { colors, FilledInput } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import responsiveStyle from "./responsiveStyle";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

const borderRadius = 8;

const theme = createTheme({
  palette: {
    primary: {
      light: "#4bd74f",
      main: "#1FCE24",
      dark: "#159019",
      contrastText: "white",
    },
    secondary: {
      main: "#CABDFF",
      dark: "#8d84b2",
      light: "#d4caff",
      contrastText: "#000",
    },
    success: { main: "#cdedd0", contrastText: "#0DAF1D" },
    warning: { main: "#fdf1d8", contrastText: colors.yellow[900] },
    error: { main: "#fccfcf", contrastText: "#F00E0E" },
    info: { main: "#B1E5FC" },
    background: { default: "#F4F4F4", paper: "#ffffff" },
  },
  typography: { fontFamily: "Work Sans" },
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true, size: "medium" },
      styleOverrides: {
        contained: {
          color: "primary.contrastText",
          borderRadius: borderRadius,
          "&.MuiButton-textError": {
            color: "#000",
          },
        },
        sizeLarge: { height: 56 },
        sizeMedium: { height: 48 },
        sizeSmall: { height: 30 },
        outlinedSecondary: {
          color: "#fff",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-error": { color: theme.palette.error.contrastText },
        }),
      },
    },

    MuiChip: {
      styleOverrides: {
        filled: { fontWeight: 500, textTransform: "capitalize" },
      },
    },

    MuiIconButton: {
      styleOverrides: { sizeMedium: { height: 36, width: 36 } },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: "p",
          body2: "p",
          button: "button",
          caption: "p",
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          inherit: "p",
          overline: "p",
          subtitle1: "p",
          subtitle2: "p",
        },
      },
      styleOverrides: {
        h2: ({ theme }) => ({
          fontWeight: 500,
          ...responsiveStyle(theme, 30, 20),
        }),
        h3: ({ theme }) => ({
          fontSize: 20,
          color: theme.palette.primary.main,
          [theme.breakpoints.down("md")]: {
            fontSize: 16,
          },
        }),
        h4: ({ theme }) => ({
          fontWeight: 500,
          ...responsiveStyle(theme, 30, 25),
        }),
        subtitle1: ({ theme }) => ({
          fontWeight: 500,
          ...responsiveStyle(theme, 17, 15),
        }),
      },
    },
    MuiAppBar: { defaultProps: { color: "inherit" } },
    MuiToolbar: { styleOverrides: { root: { paddingLeft: "24px" } } },

    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.grey[300],
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: ({ theme }) => ({ backgroundColor: theme.palette.primary.main }),
        root: { "&:last-child td": { borderBottom: "none" } },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
        head: ({ theme }) => ({
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }),
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: "filled",
        input: <FilledInput hiddenLabel disableUnderline />,
      },
      styleOverrides: {
        filled: { borderRadius: borderRadius, textTransform: "capitalize" },
      },
    },

    MuiMenuItem: {
      styleOverrides: { root: { textTransform: "capitalize" } },
    },

    MuiTextField: {
      defaultProps: {
        variant: "filled",
        hiddenLabel: true,
        InputProps: { disableUnderline: true },
        size: "medium",
      },
    },

    // Don't even dare to touch this code 😠
    MuiFilledInput: {
      defaultProps: { hiddenLabel: true },
      variants: [
        {
          props: { size: "large" },
          style: {
            height: 56,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          minHeight: 36,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 6,
          paddingRight: 6,
          border: "1px solid transparent",
          "&.Mui-focused": {
            border: "1px solid #1FCE24",
          },
        },
        input: {
          height: 24,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
        },
        inputSizeSmall: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 6,
          paddingRight: 6,
        },
        inputMultiline: { paddingTop: 6, paddingLeft: 6 },
      },
    },

    MuiCard: {
      defaultProps: { elevation: 8 },
      styleOverrides: {
        root: { boxShadow: "0px 1px 23px rgba(0,0,0,0.2)" },
      },
    },

    MuiListItemButton: {
      styleOverrides: { root: { borderRadius: borderRadius } },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: { fontWeight: 500 },
        secondary: { fontWeight: 400 },
      },
    },

    MuiSwitch: {
      defaultProps: { color: "primary" },
      styleOverrides: {
        root: { padding: "6px" },
        switchBase: ({ theme }) => ({
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.light,
            },
          },
        }),
        track: { borderRadius: "16px" },
      },
    },
  },
  shape: { borderRadius: borderRadius },
});

interface Props {
  children: React.ReactElement;
}

function MuiThemeProvider(props: Props) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default MuiThemeProvider;
