import { IChangePasswordForm } from "components/modals/ChangePasswordModal";
import { privateRequest, publicRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { IUser } from "context/AuthContext";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "./../../utils/errorHandler";

// * function name should be uppercased as we are calling hooks inside functions

interface ILoginInputs {
  username: string;
  password: string;
  otp?: string;
}

interface ILoginResp {
  error: boolean;
  data: {
    username: string;
    token: string;
    flag: "verify_email";
    google_authenticator: number;
  };
  message: string;
}

interface IUseLogin {
  onSuccess?: (token: string, keepLogedin: boolean) => void;
  onVerifyRequire?: () => void;
  onOTPRequire?: () => void;
  keepLogedin: boolean;
  onFail?: Function;
}

export const useLogin = (params: IUseLogin) => {
  return useMutation<ILoginResp, Error, ILoginInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post("auth/login", paylaod);
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        params.onSuccess &&
          data?.data?.token &&
          params.onSuccess(data?.data?.token, params.keepLogedin);
        params.onVerifyRequire && data?.data?.flag && params.onVerifyRequire();
        params.onOTPRequire &&
          data?.data?.google_authenticator === 1 &&
          params.onOTPRequire();
      },
    }
  );
};

export const useUser = (onSuccess?: Function) => {
  return useQuery<IUser, Error>(
    "fetch-user",
    async () => {
      try {
        const resp = await privateRequest.get("/profile/me");
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export interface IUserUpdateInputs {
  email: string;
  name: string;
  phone: string;
}

export const useUpdateUser = () => {
  return useMutation<ILoginResp, Error, IUserUpdateInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.put("/profile/me", paylaod);
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries("fetch-user");
      },
    }
  );
};
export interface IForgotPassInputs {
  username: string;
}
export interface IForgotPassResp {
  errors: string;
  message: string;
  token?: string;
}

export const useForgotPassMutation = () => {
  return useMutation<IForgotPassResp, Error, IForgotPassInputs>(
    async (paylaod) => {
      try {
        const resp = await publicRequest.post("/forgotPassword", paylaod);
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries("forgot-password");
      },
    }
  );
};
export interface IResetPassInputs {
  otp: string;
  new_password: string;
  token: string;
}
export interface IForgotPassResp {
  errors: string;
  message: string;
}

export const useResetPassMutation = () => {
  return useMutation<IForgotPassResp, Error, IResetPassInputs>(
    async (paylaod) => {
      try {
        const resp = await publicRequest.post("/resetPassword", paylaod);

        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries("reset-password");
      },
    }
  );
};

const changePassword = async (data: IChangePasswordForm) => {
  try {
    await privateRequest.post(`security/changePassword`, data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

interface ChangePasswordMutationParams {
  onSuccess?: Function;
  onError?: (msg: string) => void;
}
export const useChangePasswordMutation = ({
  onSuccess,
  onError,
}: ChangePasswordMutationParams) => {
  return useMutation<any, Error, IChangePasswordForm>(changePassword, {
    onSuccess: (data) => {
      onSuccess && onSuccess();
    },
    onError: (err) => {
      onError && onError(err.message);
    },
  });
};

export const resendPinChangeTwoFa = async (traceId: string) => {
  try {
    await privateRequest.post(`security/resendOTP`, { traceId: traceId });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const requestToAddPin = async (pin: any): Promise<any> => {
  try {
    const resp = await privateRequest.post(`security/requestToAddPin`, pin);
    return resp.data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

interface IChangePinParams {
  emailVerifyCode: string;
  traceId: string;
}
export const changePin = async (data: IChangePinParams): Promise<any> => {
  try {
    const resp = await privateRequest.post(`security/changePin`, data);
    return resp.data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};
