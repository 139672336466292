import { Divider, Paper, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Logo from "assets/icons/logo-dark.png";
import { useUser } from "queries/auth";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";
import {
  drawerLinks,
  drawerLinksForSubadmin,
} from "../../constants/drawerLink";
import { DrawerLinkList } from "../link";
import AppBar from "./AppBar";

const drawerWidth = 300;

export type BackButtonLinkType = string | undefined;

interface IRequireAuthProps {
  children: React.ReactElement;
  backButtonLink: BackButtonLinkType;
}

export default function ResponsiveDrawer({
  backButtonLink,
  children,
}: IRequireAuthProps) {
  let { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { data } = useUser();

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // close drawer when link changes
  React.useEffect(() => {
    setTimeout(() => {
      setMobileOpen(false);
    }, 200);
  }, [pathname]);

  const drawer = (
    <div
      className="h-screen flex flex-col px-3"
      style={{
        background: "linear-gradient(0deg, #02300C, #0D0D0D)",
      }}
    >
      <div className="flex flex-col items-center gap-8 pt-10 pb-6">
        <img width={150} src={Logo} alt="logo" />
        <h4 className="text-primary text-lg lg:text-3xl font-medium">
          Hello Admin!
        </h4>
      </div>
      <Scrollbars style={{ height: "100%" }}>
        <List>
          {/* 
            //! render menu based on role
            // ! super admin should have access to all routes
          */}
          {data?.role === "superAdmin" ? (
            <>
              {drawerLinks.map((link, i) => (
                <DrawerLinkList key={i} data={link} />
              ))}
            </>
          ) : (
            <>
              {drawerLinksForSubadmin.map((link, i) => (
                <DrawerLinkList key={i} data={link} />
              ))}
            </>
          )}
        </List>
      </Scrollbars>
    </div>
  );

  return (
    <Paper sx={{ display: "flex", minHeight: "100vh" }}>
      <Drawer
        variant={isLargeScreen ? "permanent" : "temporary"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: !isLargeScreen }} // Better open performance on mobile.
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Box flexGrow={1} width="100%">
        <AppBar
          handleDrawerToggle={handleDrawerToggle}
          backButtonLink={backButtonLink}
        />

        <Divider />
        <Box
          sx={{
            my: 3,
            flexGrow: 1,
            padding: { xs: "0px 16px", lg: "0px 24px" },
          }}
        >
          {children}
        </Box>
      </Box>
    </Paper>
  );
}
