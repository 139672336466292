import { default as MuiBackdrop } from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

type Props = {};

const CustomBackdrop = (props: Props) => {
  return (
    <MuiBackdrop sx={{ color: "#fff", zIndex: 9999999 }} open={true}>
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
};

export default CustomBackdrop;
