import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import queryClient from "config/queyClient.config";
import {
  addAdmin,
  ADMIN_LIST,
  IAdminUpdate,
  updateAdmin,
} from "queries/management/adminManagement.queries";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
  name: yup.string().required(),
  username: yup.string().required(),
});
export type IAdminCreate = yup.InferType<typeof schema>;

interface IProps {
  email?: string;
  name?: string;
  username?: string;
  userId?: string;
  [index: string]: any;
}

const AddAdminModal = NiceModal.create<IProps>(
  ({ email, name, username, userId }) => {
    const modal = useModal();
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IAdminCreate>({
      resolver: yupResolver(schema),
      defaultValues: {
        email: email,
        name: name,
        username: username,
        password: undefined,
      },
    });

    const AddAdminMutation = useMutation<any, Error, any>(addAdmin, {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_LIST);
        toast.success("Sub Admin created successfully");
        modal.remove();
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

    const updateAdminMutation = useMutation<any, Error, IAdminUpdate>(
      updateAdmin,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(ADMIN_LIST);
          toast.success("Sub Admin updated successfully");
          modal.remove();
        },
        onError: (err) => {
          toast.error(err.message);
        },
      }
    );

    const onSubmit: SubmitHandler<IAdminCreate> = (data) => {
      if (email?.length && name?.length && userId?.length) {
        updateAdminMutation.mutate({
          userId: userId,
          name: data.name,
          email: data.email,
          username: data.username,
          password: data.password,
        });
        // update this admin
      } else {
        // create new admin
        AddAdminMutation.mutate(data);
      }
    };

    const isLoading =
      AddAdminMutation.isLoading || updateAdminMutation.isLoading;

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <DialogTitle>Add/Edit Admin</DialogTitle>
        <DialogContent>
          <form
            className="flex flex-col gap-y-4 w-72"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <p className="mb-2">Name</p>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.name && (
                <Alert severity="error">{errors.name.message}</Alert>
              )}
            </div>
            <div>
              <p className="mb-2">Username</p>
              <Controller
                name="username"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    value={value}
                    onChange={onChange}
                    disabled={!!username?.length}
                  />
                )}
              />
              {errors.username && (
                <Alert severity="error">{errors.username.message}</Alert>
              )}
            </div>
            <div>
              <p className="mb-2">Email</p>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.email && (
                <Alert severity="error">{errors.email.message}</Alert>
              )}
            </div>
            <div>
              <p className="mb-2">Password</p>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    type="password"
                  />
                )}
              />
              {errors.password && (
                <Alert severity="error">{errors.password.message}</Alert>
              )}
            </div>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              Create
            </LoadingButton>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  }
);

export default AddAdminModal;
