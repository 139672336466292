import { useModal } from "@ebay/nice-modal-react";
import {
  Alert,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import CustomBackdrop from "components/loader/CustomBackdrop";
import ConsentModal from "components/modals/ConsentModal";
import EditUserInfoModal, {
  IUserEditForm,
} from "components/modals/EditUserInfoModal";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import queryClient from "config/queyClient.config";
import { debounce } from "lodash";
import {
  blockUser,
  editUser,
  IUserResponse,
  unblockUser,
  USER_LISTS,
  useUserList,
} from "queries/management/userManagement.queries";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { BiSearch } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { MdBlock } from "react-icons/md";
import { useMutation } from "react-query";
import { getDateFormated } from "utils";
import { getRank, ranks } from "utils/getRank";

type Props = {};
export const ROWS_PER_PAGE = 10;

const Management = (props: Props) => {
  const [page, setPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [rank, setRank] = useState("none");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [debounsedSearchKey, setDebounsedSearchKey] = useState("");
  const debouncedKey = useRef(
    debounce((value) => setDebounsedSearchKey(value), 600)
  );

  const { isLoading, error, data, isRefetching } = useUserList({
    search: debounsedSearchKey,
    page: page + 1,
    rank: rank,
    fromDate,
    toDate,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  if (error) {
    return <Alert severity="error">Error fetching data.</Alert>;
  }
  return (
    <div>
      <SectionTitle.Default title="User/Player  Management">
        <div className="flex gap-4 flex-wrap">
          <FormControl>
            <p className="text-xs text-gray-200">From</p>
            <DatePicker
              value={fromDate}
              onChange={(value: Date | null) => {
                setFromDate(value);
              }}
            />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">To</p>
            <DatePicker value={toDate} onChange={(value) => setToDate(value)} />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">By Rank</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rank}
              label="Age"
              onChange={(e) => setRank(e.target.value)}
            >
              <MenuItem value={"none"}>All Rank</MenuItem>
              {[...ranks].map((rank) => (
                <MenuItem value={rank} key={rank}>
                  {rank}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <p className="text-xs text-gray-200">Search</p>
            <TextField
              onChange={(e) => {
                setSearchKey(e.target.value);
                debouncedKey.current(e.target.value);
              }}
              disabled={isLoading}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <IconButton>
                    <BiSearch />
                  </IconButton>
                ),
              }}
              placeholder="Search"
              value={searchKey}
            />
          </div>
        </div>
      </SectionTitle.Default>

      <CustomTable
        columns={["Joined", "Name", "Username", "Rank", "Action"]}
        isLoading={isLoading || isRefetching}
        skeletonLength={10}
        error={error && "error occured"}
      >
        {data?.docs.map((user) => (
          <TableRow key={user._id}>
            <TableCell>
              {getDateFormated(user.createdAt, "YYYY-MM-DD HH:mm")}
            </TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <img
                  src={getRank(user.rank)}
                  alt={`rank-icon-${user.rank}`}
                  loading="lazy"
                  className="w-10 hover:scale-150"
                />
                <Chip
                  label={user.rank || "player"}
                  color="info"
                  sx={{ textTransform: "uppercase" }}
                />
              </div>
            </TableCell>
            <TableCell>
              <UserActions user={user} />
            </TableCell>
          </TableRow>
        ))}
        {data?.docs.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No user Found.</p>
        )}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        hidden={isLoading}
      />
    </div>
  );
};

export default Management;

interface IActions {
  user: IUserResponse;
}

const UserActions = ({ user }: IActions) => {
  const Consent = useModal(ConsentModal);
  const EditUserModal = useModal(EditUserInfoModal);

  /* 
    update mutation config
  */
  const updateUserMutaion = useMutation<IUserEditForm, Error, any>(editUser, {
    onError: (err) => {
      toast.error(err.message, { duration: 10000, position: "top-center" });
    },
    onSuccess: (err) => {
      toast.success(`${user.username} has been Updated`, {
        duration: 10000,
        position: "top-center",
      });
      // invalidate list cache
      queryClient.invalidateQueries(USER_LISTS);
    },
  });
  /* 
    block mutation config
  */
  const blockUserMutaion = useMutation<string, Error, any>(blockUser, {
    onError: (err) => {
      toast.error(err.message, { duration: 10000, position: "top-center" });
    },
    onSuccess: (err) => {
      toast.success(`${user.username} has been blocked`, {
        duration: 10000,
        position: "top-center",
      });
      // invalidate list cache
      queryClient.invalidateQueries(USER_LISTS);
    },
  });
  /* 
    unblock mutation config
  */
  const unblockUserMutaion = useMutation<string, Error, any>(unblockUser, {
    onError: (err) => {
      toast.error(err.message, { duration: 10000, position: "top-center" });
    },
    onSuccess: (err) => {
      toast.success(`${user.username} has been unblocked`, {
        duration: 10000,
        position: "top-center",
      });
      // invalidate list cache
      queryClient.invalidateQueries(USER_LISTS);
    },
  });

  const handleBlockBtn = () => {
    Consent.show({ heading: `Block ${user.name}` }).then((res) => {
      if (res === "yes") {
        // perform action
        blockUserMutaion.mutate(user._id);
      }
    });
  };
  const handleUnblockBtn = () => {
    Consent.show({
      heading: `Unblock ${user.name}`,
      positiveAction: true,
    }).then((res) => {
      // perform unblock action if user taps yes
      res === "yes" && unblockUserMutaion.mutate(user._id);
    });
  };

  const handleEditBtn = () => {
    EditUserModal.show({ email: user.email }).then((res) => {
      if (res) {
        const data: any = { ...(res as IUserEditForm), id: user._id };
        // perform edit mutation
        updateUserMutaion.mutate(data as IUserEditForm);
      }
    });
  };

  let isActionHappening =
    blockUserMutaion.isLoading ||
    unblockUserMutaion.isLoading ||
    updateUserMutaion.isLoading;

  return (
    <div>
      {isActionHappening && <CustomBackdrop />}
      <div className="inline-block w-20">
        {user.status === "blocked" ? (
          <Button
            variant="text"
            size="small"
            sx={{ color: "error.success" }}
            onClick={handleUnblockBtn}
          >
            UnBlock
          </Button>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={{ color: "error.contrastText" }}
            startIcon={<MdBlock />}
            onClick={handleBlockBtn}
          >
            Block
          </Button>
        )}
      </div>
      <Button
        variant="text"
        size="small"
        sx={{ color: "#000" }}
        startIcon={<FiEdit2 />}
        onClick={handleEditBtn}
      >
        Edit
      </Button>
    </div>
  );
};
