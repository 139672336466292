import { FormControl, TablePagination, TextField } from "@mui/material";
import DatePicker from "components/input/DatePicker";
import { SectionTitle } from "components/title";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import { useWithdrawHistory } from "queries/transaction-history";
import { useState } from "react";
import WithdrawTransactionTable from "./components/WithdrawTransactionTable";

interface IFilters {
  fromDate: null | Date;
  toDate: null | Date;
  status: string;
}

type Props = {};

const History = (props: Props) => {
  const [filters, setFilters] = useState<IFilters>({
    fromDate: null,
    toDate: null,
    status: "",
  });
  const [searchKey, debouncedSearch, setSearch] = useDebounceSearch();
  const [page, setPage] = usePaging();

  const { data, isLoading, error } = useWithdrawHistory({
    page: page + 1,
    filter: { search: debouncedSearch, ...filters },
  });

  return (
    <div>
      <SectionTitle.Default title="Withdrawals History">
        {/* filters */}
        <div className="flex items-center gap-3 flex-wrap justify-center">
          <FormControl>
            <p className="text-xs text-gray-200">From</p>
            <DatePicker
              value={filters.fromDate}
              onChange={(value: Date | null) => {
                setFilters({ ...filters, fromDate: value });
              }}
            />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">To</p>
            <DatePicker
              value={filters.toDate}
              onChange={(value) => setFilters({ ...filters, toDate: value })}
            />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">Search</p>
            <TextField value={searchKey} onChange={setSearch} />
          </FormControl>
        </div>
      </SectionTitle.Default>

      <WithdrawTransactionTable
        docs={data?.docs || []}
        isLoading={isLoading}
        error={error?.message}
      />
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={setPage}
        hidden={isLoading}
      />
    </div>
  );
};

export default History;
