import {
  Avatar,
  Button,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { CardUI } from "components/card";
import { SectionTitle } from "components/title";
import { AuthContext } from "context/AuthContext";
import React, { FC, useContext } from "react";
import { Controller, useForm } from "react-hook-form";

interface IUser {
  name: string;
  email: string;
}

const ProfileCard: FC = () => {
  const { user } = useContext(AuthContext);

  const { control, handleSubmit } = useForm<IUser>({
    defaultValues: {
      name: user?.data?.name ?? "",
      email: user?.data?.email ?? "",
    },
  });

  const formSubmit = (data: IUser) => {
    console.log(data);
  };

  return (
    <CardUI className="flex flex-col gap-8">
      <div className="flex gap-4 items-center">
        {user?.data?.photo ? (
          <img
            className="w-16 h-16 rounded-full"
            src={user?.data?.photo}
            alt="avatar"
          />
        ) : (
          <Avatar sx={{ width: 64, height: 64 }} />
        )}
        <Typography variant="h6">{user?.data?.name ?? ""}</Typography>
      </div>

      <SectionTitle.Default title="General Info" />

      <form onSubmit={handleSubmit(formSubmit)} className="flex flex-col gap-2">
        <InputLabel>Username</InputLabel>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error, invalid } }) => (
            <TextField
              {...field}
              placeholder="Name"
              type="text"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />

        <div className="h-2" />

        <InputLabel>Email</InputLabel>
        <Controller
          name="email"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error, invalid } }) => (
            <TextField
              placeholder="John@email.com"
              type="email"
              {...field}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />

        {/* <div className="h-2" />

        <InputLabel>Phone</InputLabel>
        <TextField placeholder="Phone" type="tel" /> */}

        <div className="h-4" />

        <Button type="submit" variant="outlined">
          Update
        </Button>
      </form>
    </CardUI>
  );
};

export default ProfileCard;
