import { Avatar } from "@mui/material";
import { forwardRef } from "react";

interface IMessageProps {
  avatar?: string;
  username: string;
  message: string;
  timestamp: string;
  self?: boolean;
}

const Message = forwardRef<HTMLDivElement, IMessageProps>(
  ({ message, username, timestamp, self, avatar }, ref) => {
    return (
      <div className={self ? "ml-auto" : "ml-0"} ref={ref}>
        <div className="flex items-start gap-2">
          <Avatar alt="Remy Sharp" src={avatar} className="uppercase">
            {username[0]}
          </Avatar>
          <div
            className={`flex flex-col ${
              self ? "order-first items-start" : "order-last"
            }`}
          >
            {!self && (
              <p className="text-[#888E9D] text-base">
                {username}
                <span className="text-xs"> ({timestamp})</span>
              </p>
            )}
            <p className="break-all">{message}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default Message;
