import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export const DROPDOWN_FILTER = "DROPDOWN_FILTER";
type FilterType = "rank" | "criteria";

type IFilterResponse<T extends FilterType> = Record<T, string[]>;

export const useDropdownFilter = <T extends FilterType>(type?: T) =>
  useQuery<IFilterResponse<T>, Error>([DROPDOWN_FILTER, type], async () => {
    try {
      const resp = await privateRequest.get(`filterTree`, {
        params: {
          filter: type,
        },
      });
      return type
        ? {
            [type]: resp.data?.data,
          }
        : resp.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
