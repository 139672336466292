type ColorType = {
  colors: string[];
};

const gradientColor = (): ColorType[] => [
  {
    colors: ["#17DB1F", "#095E1B"],
  },
  {
    colors: ["#6ABAE6", "#1D719F"],
  },
  {
    colors: ["#DC4A95", "#8D337A"],
  },
  {
    colors: ["#6354E1", "#270C5C"],
  },
  {
    colors: ["#B79891", "#94716B"],
  },
  {
    colors: ["#BBD2C5", "#536976"],
  },
  {
    colors: ["#076585", "#fff"],
  },
  {
    colors: ["#00467F", "#A5CC82"],
  },
  {
    colors: ["#1488CC", "#2B32B2"],
  },
  {
    colors: ["#ec008c", "#fc6767"],
  },
  {
    colors: ["#cc2b5e", "#753a88"],
  },
  {
    colors: ["#2193b0", "#6dd5ed"],
  },
  {
    colors: ["#e65c00", "#F9D423"],
  },
  {
    colors: ["#2b5876", "#4e4376"],
  },
  {
    colors: ["#314755", "#26a0da"],
  },
];

export default gradientColor;
