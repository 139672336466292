import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

const EnterPin = NiceModal.create(() => {
  const modal = useModal();
  const [pin, setPin] = useState("");

  const onSubmit = () => {
    modal.resolve(pin);
    modal.remove();
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle>Enter Pin</DialogTitle>
      <DialogContent className="w-full md:w-[350px]">
        <TextField
          onChange={(e) => setPin(e.target.value)}
          value={pin}
          type="password"
          fullWidth
        />
        <Button
          variant="contained"
          sx={{ mx: "auto", display: "block", mt: 2 }}
          onClick={() => onSubmit()}
        >
          Confirm
        </Button>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
});

export default EnterPin;
