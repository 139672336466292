import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TwoFaForm from "components/form/OtpForm";
import PinChangeForm from "components/form/PinChangeForm";
import { changePin, resendPinChangeTwoFa } from "queries/auth";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";

const ChangePinModal = NiceModal.create(() => {
  const modal = useModal();
  const [traceId, setTraceId] = useState<string | null>(null);
  const [otp, setOtp] = useState<string>("");

  /* 
     Resend Otp Code
  */
  const ResendMutation = useMutation<any, Error, any>(resendPinChangeTwoFa, {
    onSuccess: () => {
      toast.success("Code resent successfully");
    },
  });

  /* 
     Change pin request
  */
  const ChangePinMutation = useMutation<any, Error, any>(changePin, {
    onSuccess: () => {
      toast.success("Pin Changed successfully");
      modal.remove();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleUpdatePin = () => {
    ChangePinMutation.mutate({ traceId, emailVerifyCode: otp });
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle>Change Pin</DialogTitle>
      <DialogContent className="w-full md:w-[350px]">
        {traceId ? (
          <div>
            <TwoFaForm
              value={otp}
              onChange={setOtp}
              resendMutation={ResendMutation}
              traceId={traceId}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={ChangePinMutation.isLoading}
              onClick={handleUpdatePin}
            >
              Submit
            </LoadingButton>
          </div>
        ) : (
          <PinChangeForm setTrace={setTraceId} />
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
});

export default ChangePinModal;
