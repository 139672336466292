import {
  Chip,
  MenuItem,
  Select,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import { useAgentCommission } from "queries/reports/player_balance_query";
import { useEffect, useState } from "react";
import { getRank, ranks, Ranks } from "utils/getRank";

type Props = {};

const COLUMNS = [
  "Username",
  "Rank",
  "Current Commissions",
  "Total Commissions",
];

const AgentCommissionPage = (props: Props) => {
  const [page, setPage, resetPageHandler] = usePaging();
  const [rank, setRank] = useState<Ranks | "">("");
  const [search, debouncedSearch, setSearch] = useDebounceSearch();

  const rankChangeHanlder = ({ target: { value } }: any) => {
    setRank(value as Ranks);
    resetPageHandler();
  };

  useEffect(() => {
    resetPageHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const { isLoading, error, data } = useAgentCommission({
    page: page + 1,
    search: debouncedSearch,
    rank,
  });

  console.log(data, "data");

  return (
    <div>
      <SectionTitle.Default title="Agent Commissions ">
        <div className="flex gap-4 flex-wrap">
          <Select displayEmpty value={rank} onChange={rankChangeHanlder}>
            <MenuItem value="">All Rank</MenuItem>
            {ranks?.map((rank, i) => (
              <MenuItem key={i} value={rank}>
                {rank?.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
          <TextField
            value={search}
            onChange={setSearch}
            placeholder="Username"
          />
        </div>
      </SectionTitle.Default>
      <CustomTable
        columns={COLUMNS}
        isLoading={isLoading}
        error={error?.message}
      >
        {data?.docs?.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row?.user}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <img
                  src={getRank(row?.rank)}
                  alt={`rank-icon-${row?.rank}`}
                  loading="lazy"
                  className="w-10 hover:scale-150"
                />
                <Chip
                  label={row?.rank || "player"}
                  color="info"
                  sx={{ textTransform: "uppercase" }}
                />
              </div>
            </TableCell>
            <TableCell>{row?.currentCommission?.toFixed(2)} PHP</TableCell>
            <TableCell>{row?.totalCommission?.toFixed(2)} PHP</TableCell>
          </TableRow>
        ))}
        {data?.docs?.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No Data Found.</p>
        )}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={setPage}
        hidden={isLoading}
      />
    </div>
  );
};

export default AgentCommissionPage;
