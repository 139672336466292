import {
  FormControl,
  Tab,
  TablePagination,
  Tabs,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import { SectionTitle } from "components/title";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import { useDisputeWithdrawList } from "queries/transaction-history";
import { useCallback, useState } from "react";
import WithdrawTransactionTable from "./components/WithdrawTransactionTable";

interface IFilters {
  fromDate: null | Date;
  toDate: null | Date;
}

type TabType = "onGoing" | "resolved";
const tabValueForFilter: Record<TabType, string> = {
  onGoing: "disputed",
  resolved: "resolved",
};

type Props = {};

const ResolutionList = (props: Props) => {
  const [filters, setFilters] = useState<IFilters>({
    fromDate: null,
    toDate: null,
  });
  const [searchKey, debouncedSearch, setSearch] = useDebounceSearch();
  const [page, setPage] = usePaging();
  const [tab, setTab] = useState<TabType>("onGoing");

  const { data, isLoading, error } = useDisputeWithdrawList({
    page: page + 1,
    filter: {
      search: debouncedSearch,
      status: tabValueForFilter[tab],
      ...filters,
    },
  });

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setTab(newValue as TabType);
    },
    []
  );

  return (
    <div>
      <SectionTitle.Default title="Resolution Center">
        <div className="flex gap-4 flex-wrap w-full items-center justify-between my-6">
          <div>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="On Going" value={"onGoing"} />
              <Tab label="Resolved" value={"resolved"} />
            </Tabs>
          </div>
          <div className="flex items-center gap-3 flex-wrap justify-center">
            <FormControl>
              <p className="text-xs text-gray-200">From</p>
              <DatePicker
                value={filters.fromDate}
                onChange={(value: Date | null) => {
                  setFilters({ ...filters, fromDate: value });
                }}
              />
            </FormControl>
            <FormControl>
              <p className="text-xs text-gray-200">To</p>
              <DatePicker
                value={filters.toDate}
                onChange={(value) => setFilters({ ...filters, toDate: value })}
              />
            </FormControl>
            <FormControl>
              <p className="text-xs text-gray-200">Search</p>
              <TextField value={searchKey} onChange={setSearch} />
            </FormControl>
          </div>
        </div>

        <WithdrawTransactionTable
          docs={data?.docs || []}
          isLoading={isLoading}
          error={error?.message}
        />
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data?.totalDocs || 0}
          rowsPerPage={10}
          page={page}
          onPageChange={setPage}
          hidden={isLoading}
        />
      </SectionTitle.Default>
    </div>
  );
};

export default ResolutionList;
