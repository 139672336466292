import {
  Alert,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

type Props = {
  minWidth?: number;
  columns: string[];
  isLoading?: boolean;
  error?: string | null;
  /* num of skeleton to show if loading is true */
  skeletonLength?: number;
  children?: React.ReactNode;
};

const CustomTable = ({
  minWidth = 650,
  columns,
  skeletonLength = 5,
  error = null,
  isLoading = false,
  children,
}: Props) => {
  const LoadingSkeleton = Array(skeletonLength)
    .fill("")
    .map((_, i) => (
      <TableRow key={i}>
        {columns.map((_, i) => (
          <TableCell key={i}>
            <Skeleton variant="text" width={"80%"} />
          </TableCell>
        ))}
      </TableRow>
    ));

  return (
    <TableContainer sx={{ mt: 3 }}>
      <Table stickyHeader sx={{ minWidth: minWidth }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && LoadingSkeleton}
          {!isLoading && !error && children}
          {error && <Alert severity="error">Error occured</Alert>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
