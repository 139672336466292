import { useState } from "react";

const usePaging = () => {
  const [page, setPage] = useState(0);

  const handlePageChagne = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const resetPageHandler = () => {
    setPage(0);
  };

  return [page, handlePageChagne, resetPageHandler] as const;
};

export default usePaging;
