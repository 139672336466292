import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface IProps {
  heading?: string;
  /* render yes button in green if its true */
  positiveAction?: boolean;
  [index: string]: any;
}

const ConsentModal = NiceModal.create<IProps>(
  ({ heading = "", positiveAction = false }) => {
    const modal = useModal();
    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <DialogContent>
          <DialogTitle>
            Are you sure you want to{" "}
            <span
              className={`${
                positiveAction ? "text-green-700" : "text-red-600"
              }`}
            >
              {heading || "perform this action "}
            </span>{" "}
            ?
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={positiveAction ? "success" : "error"}
            onClick={() => {
              modal.resolve("yes");
              modal.remove();
            }}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={() => modal.remove()}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ConsentModal;
