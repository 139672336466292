import { privateRequest, publicRequest } from "config/axios.config";
import { useInfiniteQuery, useQuery } from "react-query";
import { formatFilters } from "utils";
import { axiosErrorHandler } from "utils/errorHandler";

export interface ICasinoGames {
  category: string;
  freerounds_supported: boolean;
  gamename: string;
  has_jackpot: boolean;
  id: string;
  id_hash: string;
  id_hash_parent: string;
  id_parent: number;
  image: string;
  image_background: string;
  image_bw: string;
  image_filled: string;
  image_preview: string;
  licence: string;
  mobile: true;
  name: string;
  new: string;
  play_for_fun_supported: boolean;
  plays: string;
  position: string;
  report: string;
  rtp: string;
  subcategory: string;
  system: string;
  type: string;
}

interface ICasinoGamesResp {
  docs: ICasinoGames[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  offset: number;
  page: number;
  pagingCounter: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export const useCasinoGames = (query: string) => {
  return useQuery<ICasinoGames[], Error>(
    ["fetch-casino-games", query],
    async () => {
      try {
        const resp = await publicRequest.get("public/gameList" + query);
        return resp?.data?.data?.docs;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!query,
    }
  );
};

export const useGamesAll = (query: string) => {
  return useInfiniteQuery<ICasinoGamesResp, Error>(
    ["fetch-games-list", query],
    async () => {
      try {
        const resp = await publicRequest.get("public/gameList" + query);
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!query,
      getNextPageParam: (results) => results.hasNextPage,
    }
  );
};

export interface IGameResp {
  _id: string;
  category: string;
  gamename: string;
  image: string;
  name: string;
}

interface IGameListResponse {
  docs: IGameResp[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  offset: number;
  page: number;
  pagingCounter: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export const GAME_LIST = "GAME_LIST";
export interface GameListParams {
  isHot?: string;
  search?: string;
  type?: string;
}
export const useInfiniteGameList = (params?: GameListParams) =>
  useQuery<IGameListResponse, Error>(
    [GAME_LIST, { ...(params || {}) }],
    async () => {
      try {
        const resp = await privateRequest.get(`game/list`, {
          params: {
            limit: "-1",
            ...formatFilters(params || {}),
          },
        });
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );

export const addToHotList = async (ids: string[]) => {
  console.log(ids);
  try {
    await privateRequest.post("game/addToHotList", { ids });
  } catch (error) {
    axiosErrorHandler(error);
  }
};
export const removeFromHotList = async (ids: string[]) => {
  try {
    await privateRequest.post("game/removeFromHotList", { ids });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export interface GameReportsParams {
  search?: string;
}

interface IGameReport {
  _id: string;
  purchaseCount: number;
  grossTurnOver: number;
  totalWin: number;
  totalLoss: number;
  users: string[];
  name: string;
  pnl: number;
  userCount: number;
}

export interface IGameReportsResponse {
  docs: IGameReport[];
  totalPnl: number;
  totalUserCount: number;
  totalTurnOver: number;
}

export const useInfiniteGameReports = (params?: GameReportsParams) =>
  useQuery<IGameReportsResponse, Error>(
    ["game-reports", { ...(params || {}) }],
    async () => {
      try {
        const resp = await privateRequest.get(`report/game`, {
          params: {
            limit: "-1",
            ...formatFilters(params || {}),
          },
        });
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
