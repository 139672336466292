import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { IPaginatedResponse } from "types";
import { formatFilters } from "utils";
import { axiosErrorHandler } from "./../../utils/errorHandler";

export const TRANSACTION_HISTORY = "TRANSACTION_HISTORY";

type TransactionCriteria =
  | "commission"
  | "withdraw"
  | "reward"
  | "purchase"
  | "transfer"
  | null;

interface ITransactionParams {
  page?: number;
  filter?: Record<string, any>;
}

export interface ITransactionResponse {
  _id: string;
  txid: string;
  hash: any;
  address: any;
  currencyCode: string;
  user: {
    _id: string;
    username: string;
    rank: string;
    email: string;
  };
  platform: string;
  note: string;
  transactionMedia: string;
  criteria: TransactionCriteria;
  flag: any;
  transactionType: string;
  amount: number;
  equivalentAmount: number;
  status: string;
  createdAt: string;
}

export interface ITransactionListsResponse extends IPaginatedResponse {
  docs: ITransactionResponse[];
}

export const useTransactionHistory = ({
  page = 1,
  filter,
}: ITransactionParams = {}) => {
  return useQuery<ITransactionListsResponse, Error>(
    ["TRANSACTION_HISTORY", page, filter],
    async () => {
      try {
        const resp = await privateRequest.get(
          `withdrawalManagement/transactionHistory`,
          {
            params: {
              page: page,
              ...formatFilters(filter ?? {}),
            },
          }
        );
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

export interface IWithdrawHistoryResponse extends ITransactionResponse {
  issuer: {
    _id: string;
    name: string;
    username: string;
    photo: string;
    rank: string;
    email: string;
  };
  paymentMethod: {
    title: string;
    description: string;
    type: string;
    timeWindow: number;
    currency: string;
  };
}
export interface IWithdrawHistoryListResponse extends IPaginatedResponse {
  docs: IWithdrawHistoryResponse[];
}

export const useWithdrawHistory = ({
  page = 1,
  filter,
}: ITransactionParams = {}) => {
  return useQuery<IWithdrawHistoryListResponse, Error>(
    ["WITHDRAW_HISTORY", page, filter],
    async () => {
      try {
        const resp = await privateRequest.get(`withdraw/list`, {
          params: {
            page: page,

            ...formatFilters(filter ?? {}),
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

export const useDisputeWithdrawList = ({
  page = 1,
  filter,
}: ITransactionParams = {}) => {
  return useQuery<IWithdrawHistoryListResponse, Error>(
    ["Dispute-WITHDRAW-HISTORY", page, filter],
    async () => {
      try {
        const resp = await privateRequest.get(`/withdraw/list`, {
          params: {
            page: page,
            ...formatFilters(filter ?? {}),
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

export const Dispute_WITHDRAW_DETAILS = "Dispute_WITHDRAW_DETAILS";
export interface IDisputeDetailResponse extends IWithdrawHistoryResponse {
  externalTransactionStatus: string;
  externalTransactionPaidAt: string;
  withdrawResolvedTo: "sender" | "receiver" | "none";
  disputedAt: null | string;
}
export const useDisputeDetails = (id = "") => {
  return useQuery<IDisputeDetailResponse, Error>(
    [Dispute_WITHDRAW_DETAILS, id],
    async () => {
      try {
        const resp = await privateRequest.get(`withdraw/details/${id}`);
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

export type ReleaseToType = "sender" | "receiver";
export interface IReleaseParams {
  id: string;
  releaseTo: ReleaseToType;
  pin: string;
}
export const releaseAsset = async (params: IReleaseParams): Promise<any> => {
  try {
    const resp = await privateRequest.post("withdraw/resolve", { ...params });
    return resp.data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export interface IChat {
  attachment: [];
  content: string;
  conversation: string;
  createdAt: Date;
  ref: null;
  sender: {
    name: string;
    username: string;
    photo: string;
    _id: string;
    email: string;
  };
  updatedAt: Date;
  _id: string;
}

export interface IMessage {
  conversationId: string;
  message: string;
  attachment?: any;
}
export const sendMessage = async (data: IMessage): Promise<any> => {
  try {
    const resp = await privateRequest.post(`chat/sendMessage`, data);
    return resp.data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export interface IChatRespons extends IPaginatedResponse {
  docs: IChat[];
}

export const useMessages = (id: string) =>
  useQuery<IChatRespons, Error>(["chat-messages", id], async () => {
    try {
      const resp = await privateRequest.get(`chat/getMessages/${id}`);
      return resp.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
