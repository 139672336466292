const InputStyle = {
  fieldset: {
    border: "none",
  },
  ">div": {
    height: "45px",
    background: "#F4F4F4",
    borderRadius: "10px",
    overflow: "hidden",
    "~p": {
      ml: 0,
    },
    "&:hover fieldset": {
      border: "none",
    },
  },
  input: {
    height: "100%",
    padding: "10px",
  },
  ".MuiFormHelperText-root": {
    lineHeight: "18px",
    marginTop: 0,
  },
};

const InputStyleTwo = {
  height: "45px",
  background: "#F4F4F4",
  borderRadius: "10px",
  overflow: "hidden",
  fieldset: {
    border: "none",
  },
  ">div": {
    height: "100%",
    "&:hover fieldset": {
      border: "none",
    },
  },
  input: {
    height: "100%",
    padding: "10px",
  },
  ".MuiFormHelperText-root": {
    lineHeight: "18px",
    marginTop: 0,
  },
};

const InputStyleThree = {
  height: "45px",
  background: "#FCFCFC",
  borderRadius: "10px",
  overflow: "hidden",
  fieldset: {
    border: "none",
  },
  ">div": {
    height: "100%",
    "&:hover fieldset": {
      border: "none",
    },
  },
  input: {
    height: "100%",
    padding: "0 10px 0 0",
  },
  ".MuiFormHelperText-root": {
    lineHeight: "18px",
    marginTop: 0,
  },
};

const grayButtonStyle = {
  bgcolor: "#6F767E",
  "&:hover": {
    bgcolor: "#777d85",
  },
};

const labelStyleOne = {
  fontSize: "14px",
  color: "#33383F",
  fontWeight: 600,
  display: "block",
  marginBottom: "12px",
};

export const InputStyles = {
  default: InputStyle,
  secondary: InputStyleTwo,
  third: InputStyleThree,
  noArrow: {
    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "input[type=number]": {
      MozAppearance: "textfield",
    },
  },
};

export const buttonStyle = {
  gray: grayButtonStyle,
};

export const labelStyle = {
  default: labelStyleOne,
};

export const errorStyles = {
  default: {
    color: "#eb6755",
    fontSize: "14px",
    marginTop: "3px",
  },
};
