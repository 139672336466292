import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { GCSchema } from "routes/gc-management/components/GCTransferForm";
import { IPaginatedResponse } from "types";
import { axiosErrorHandler } from "utils/errorHandler";

export const GC_REQUESTS = "GC_REQUESTS";

export interface IGCRequest {
  _id: string;
  txid: string;
  currencyCode: string;
  user: User;
  issueer: User;
  amount: number;
  equivalentAmount: number;
  note: string;
  status: string;
  createdAt: string;
  select: string;
}

interface User {
  _id: string;
  name: string;
  username: string;
  photo?: any;
  email: string;
  rank: string;
}

interface IGCRequestLists extends IPaginatedResponse {
  docs: IGCRequest[];
}

export const gcTransfer = async (data: GCSchema): Promise<any> => {
  try {
    await privateRequest.post(`gcManagement/transfer`, data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

interface IGCRequestFilter {
  search?: string;
  page?: number;
  status?: string;
}

export const useGcRequests = ({ page = 1, ...rest }: IGCRequestFilter = {}) =>
  useQuery<IGCRequestLists, Error>(
    [GC_REQUESTS, page, { ...rest }],
    async () => {
      try {
        const resp = await privateRequest.get(`gcManagement/list`, {
          params: {
            page: page,
            ...rest,
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );

export const declineGcRequest = async ({
  id,
  note,
}: {
  id: string;
  note: string;
}): Promise<any> => {
  try {
    await privateRequest.post(`gcManagement/decline`, { id, note });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const approveGcRequest = async ({
  id,
}: {
  id: string;
}): Promise<any> => {
  try {
    await privateRequest.post(`gcManagement/approve`, { id });
  } catch (error) {
    axiosErrorHandler(error);
  }
};
