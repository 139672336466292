import { Chip, TableCell, TableRow } from "@mui/material";
import CustomTable from "components/table/Table";
import { ITransactionResponse } from "queries/transaction-history";
import React from "react";
import { getDateFormated } from "utils";

type ChipKeys =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

export const statusColors: Record<string, ChipKeys> = {
  pending: "warning",
  confirmed: "success",
  approved: "success",
  declined: "error",
  rejected: "error",
  block: "error",
  unblock: "success",
};

type Props = {
  isLoading?: boolean;
  error?: string;
  docs: ITransactionResponse[];
};

const TransactionTable = ({ docs, isLoading, error }: Props) => {
  return (
    <CustomTable
      columns={["Timestamp", "TXID", "Username", "Type", "Amount", "Status"]}
      isLoading={isLoading}
      error={error}
    >
      {docs?.map((row) => (
        <TableRow key={row._id}>
          <TableCell>{getDateFormated(row.createdAt)}</TableCell>
          <TableCell>{row.txid}</TableCell>
          <TableCell>{row.user.username}</TableCell>
          <TableCell>{row.criteria}</TableCell>
          {/* <TableCell>
            {row.amount} {row.currencyCode}
          </TableCell> */}
          <TableCell>{row.equivalentAmount?.toFixed(2)} PHP</TableCell>
          <TableCell>
            {<Chip label={row.status} color={statusColors[row.status]} />}
          </TableCell>
        </TableRow>
      ))}
      {docs.length === 0 && (
        <p className="text-yellow-600 p-3 text-lg">No Data Found.</p>
      )}
    </CustomTable>
  );
};

export default React.memo(TransactionTable);
