import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import queryClient from "config/queyClient.config";
import { gcTransfer } from "queries/management/gcManagement.queries";
import { TRANSACTION_HISTORY } from "queries/transaction-history";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import * as yup from "yup";

const schema = yup.object({
  username: yup.string().required("required"),
  amount: yup.string().required("required"),
  pin: yup.string().min(4).required("required"),
});
export type GCSchema = yup.InferType<typeof schema>;

type Props = {};

const GCTransferForm = (props: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GCSchema>({
    resolver: yupResolver(schema),
  });

  const GCTransferMutaion = useMutation<any, Error, any>(gcTransfer, {
    onSuccess: () => {
      toast.success("Transferred successfully");
      reset({
        amount: undefined,
        username: "",
        pin: "",
      });
      queryClient.invalidateQueries(TRANSACTION_HISTORY);
    },
    onError: (err) => {
      toast.error("Transaction Failed : " + err.message);
    },
  });

  const onSubmit: SubmitHandler<GCSchema> = (data) => {
    GCTransferMutaion.mutate(data);
  };

  return (
    <div className="max-w-[450px] p-8 bg-gray rounded-lg">
      <h4 className="text-primary font-semibold mb-4 text-xl">GC Transfer</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6">
        <div>
          <p>Username</p>
          <Controller
            name="username"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth
                value={value}
                onChange={onChange}
                error={Boolean(errors?.username)}
                helperText={errors?.username?.message}
              />
            )}
          />
        </div>
        <div>
          <p>GC Amount</p>
          <Controller
            name="amount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{ disableUnderline: true }}
                type="number"
                value={value}
                onChange={onChange}
                fullWidth
                error={Boolean(errors?.amount)}
                helperText={errors?.amount?.message}
              />
            )}
          />
        </div>
        <div>
          <p>Pin</p>
          <Controller
            name="pin"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputProps={{ disableUnderline: true }}
                type="password"
                value={value}
                onChange={onChange}
                fullWidth
                error={Boolean(errors?.pin)}
                helperText={errors?.pin?.message}
              />
            )}
          />
        </div>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={GCTransferMutaion.isLoading}
          disabled={GCTransferMutaion.isLoading}
        >
          Send
        </LoadingButton>
      </form>
    </div>
  );
};

export default GCTransferForm;
