import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, CircularProgress, TextField } from "@mui/material";
import Logo from "assets/icons/logo-dark.png";
import { RouteLinks } from "constants/routeLinks";
import { IForgotPassInputs, useForgotPassMutation } from "queries/auth";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { InputStyles } from "styles/form";
import { landingAppUrl } from "utils/urls";
import * as yup from "yup";

const schema = yup.object({
  username: yup.string().required(),
});

type Props = {};

const ForgotPassword = (props: Props) => {
  const { isLoading, mutate, error, data } = useForgotPassMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForgotPassInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IForgotPassInputs> = (
    data: IForgotPassInputs
  ) => {
    const payload = {
      username: data.username,
    };
    mutate(payload);
  };

  if (data && data.token) {
    return (
      <Navigate
        to={RouteLinks.RESET_PASSWORD.link + "/" + data.token}
        replace
        // state={data.token}
      />
    );
  }

  return (
    <div className="p-3 lg:min-h-screen bg-primary-background w-full flex items-center justify-center">
      <div className="px-4 lg:px-12 xl:px-36 py-4 lg:pt-12 xl:pt-16 lg:pb-12 xl:pb-20 bg-white rounded-2.5xl w-full md:w-3/6 xl:w-[842px]">
        <div className="mb-10 lg:mb-16 text-center">
          <img
            className="inline-block mb-12 mt-3"
            width={150}
            src={Logo}
            alt="logo"
          />
          <h1 className="text-xl md:text-2xl xl:text-3xl text-d-black font-semibold">
            Forgot Password
          </h1>
        </div>
        {/* ========================================== */}

        <form
          className="flex flex-col gap-3 lg:gap-6 w-full select-none"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={InputStyles.default}
                fullWidth
                placeholder="Username"
                {...field}
                error={!!errors.username}
                helperText={errors.username?.message}
              />
            )}
          />

          <div className="flex items-center justify-between">
            <Link to={RouteLinks.LOGIN.link} replace>
              <p className="text-primary font-semibold text-sm cursor-pointer">
                Back to login
              </p>
            </Link>
          </div>

          <Button
            variant="contained"
            disableElevation
            color="primary"
            fullWidth
            size="large"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Send OTP"}
          </Button>
          {error && <Alert severity="error">{error?.message}</Alert>}

          <p className="text-center text-d-black font-bold text-sm">
            Don’t have an account?{" "}
            <a
              className="text-primary"
              target={"_blank"}
              href={`${landingAppUrl}/signup`}
              rel="noreferrer"
            >
              Sign up
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
