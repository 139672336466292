import { RankType } from "queries/rank";
import React, { FC, useState } from "react";
import {
  getTokenFromCache,
  removeTokenFromCache,
  setTokenToLocalStorage,
  setTokenToSessionStorage,
} from "utils/localStorage";

export enum USER_STATUS {
  "a" = "A",
}

export type UserStatusTypes = "activated" | "pending" | "blocked";

export type UserRoleTypes = "user" | "agent";

export interface IUser {
  accountVerifiedAt: null | Date;
  country: string;
  createdAt: Date;
  deletedAt: null | Date;
  dialCode: null | string;
  email: string;
  googleAuthentication: boolean;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  name: string;
  password: string;
  passwordChangedAt: null | Date;
  passwordResetExpireAt: null | Date;
  passwordResetToken: null | string;
  phone: null | string;
  photo: string;
  rank: RankType;
  referralId: string;
  role: string;
  sponsor: {
    name: string;
    username: string;
    photo: null | string;
    _id: string;
    email: string;
  };
  status: UserStatusTypes;
  hasPin: boolean;
  _id: string;
}

interface IInitialState {
  token: string | null;
  user: {
    isLoading: boolean;
    data: null | IUser;
  };
}

interface IAuth extends IInitialState {
  loginStart: () => void;
  loginFinish: (token: string) => void;
  logout: (cb?: Function) => void;
  setUser: (user: IUser) => void;
}

const intialState = {} as IAuth;

export const AuthContext = React.createContext<IAuth>(intialState);

interface Props {
  children: React.ReactElement;
}

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState<IInitialState>({
    token: getTokenFromCache(),
    user: {
      isLoading: false,
      data: null,
    },
  });

  const loginFinish = (token: string, keepLogedin: boolean = true) => {
    if (keepLogedin) {
      setTokenToLocalStorage(token);
    } else {
      setTokenToSessionStorage(token);
    }
    setAuth({ ...auth, token: token });
  };

  const loginStart = () => {
    setAuth({ ...auth });
  };

  const logout = (cb: Function = () => {}) => {
    removeTokenFromCache();

    setAuth({
      token: null,
      user: {
        data: null,
        isLoading: false,
      },
    });
    cb && cb(); //call the callback function if passed on
  };

  const setUser = (user: IUser) => {
    let newUser = {
      isLoading: false,
      data: user,
    };
    setAuth({ ...auth, user: newUser });
  };

  return (
    <AuthContext.Provider
      value={{ ...auth, loginStart, loginFinish, logout, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
