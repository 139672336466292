import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { RouteGroups, RouteLinks } from "constants/routeLinks";
import React, { FC, useEffect } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { IDrawerChild, IDrawerLink } from "../../constants/drawerLink";

const DrawerItemButtom = styled(ListItemButton)({
  marginBottom: "8px",
  color: "white",
  "& .MuiListItemIcon-root": {
    color: "white",
    fontSize: "24px",
  },
});

interface Props {
  data: IDrawerLink;
}

const DrawerLinkList: FC<Props> = ({ data }) => {
  const hasChild = data && data.child?.length;

  if (hasChild) {
    return <DrawerLinkWithChild data={data} />;
  }

  return (
    <NavLink to={`${data.link}`} end={data.link === RouteLinks.DASHBOARD.link}>
      {({ isActive }) => (
        <DrawerItemButtom key={data.label} selected={isActive}>
          <ListItemIcon>
            <data.icon />
          </ListItemIcon>
          <ListItemText>{data.label}</ListItemText>
        </DrawerItemButtom>
      )}
    </NavLink>
  );
};

export default DrawerLinkList;

const DrawerLinkWithChild: FC<{ data: IDrawerLink }> = ({ data }) => {
  let { pathname } = useLocation();
  const currentRoute = pathname
    .replaceAll("/", "")
    .replace(RouteGroups.USER.link, "")
    .replace(RouteGroups.AGENT.link, "");
  let isParentActive = data?.child?.some((e) => e.link === currentRoute);
  const [open, setOpen] = React.useState(isParentActive);

  useEffect(() => {
    // close dropdown if not active
    if (!isParentActive) {
      setOpen(false);
    }
  }, [isParentActive, data.link]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <DrawerItemButtom onClick={handleClick}>
        <ListItemIcon sx={{ fontSize: { xs: 20, lg: 24 } }}>
          <data.icon />
        </ListItemIcon>
        <ListItemText primary={data.label} />
        {open ? <MdExpandLess /> : <MdExpandMore />}
      </DrawerItemButtom>

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ paddingLeft: "32px" }}
      >
        <List component="div">
          {data.child?.map((link: IDrawerChild, i) => (
            <NavLink key={link.label} to={link.link}>
              {({ isActive }) => (
                <DrawerItemButtom key={link.label} selected={isActive}>
                  <ListItemIcon>
                    <link.icon />
                  </ListItemIcon>
                  <ListItemText>{link.label}</ListItemText>
                </DrawerItemButtom>
              )}
            </NavLink>
          ))}
        </List>
      </Collapse>
    </div>
  );
};
