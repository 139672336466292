import NiceModal from "@ebay/nice-modal-react";
import queryClient from "config/queyClient.config";
import AuthContext from "context/AuthContext";
import SocketContextProvider from "context/SocketContext";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import MuiThemeProvider from "theme/MuiTheme";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider>
      <AuthContext>
        <SocketContextProvider>
          <QueryClientProvider client={queryClient}>
            <NiceModal.Provider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </NiceModal.Provider>
          </QueryClientProvider>
        </SocketContextProvider>
      </AuthContext>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
