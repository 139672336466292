import { Avatar } from "@mui/material";
import React from "react";

type Props = {
  name: string;
  photo?: string;
};

const UserAvatar = ({ name, photo }: Props) => {
  return (
    <div className="flex items-center gap-x-2">
      <Avatar
        alt="Remy Sharp"
        src={photo}
        sx={{
          width: 28,
          height: 28,
          background: "rgb(31 206 36)",
          textTransform: "capitalize",
        }}
      >
        {name[0]}
      </Avatar>
      {name}
    </div>
  );
};

export default UserAvatar;
