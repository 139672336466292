import { IAdminCreate } from "components/modals/AddAdminModal";
import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { IPaginatedResponse } from "types";
import { axiosErrorHandler } from "utils/errorHandler";

export const ADMIN_LIST = "ADMIN_LIST";

export interface IAdmin {
  _id: string;
  name: string;
  username: string;
  phone?: any;
  dialCode?: any;
  status: string;
  email: string;
  createdAt: string;
}

export interface IUserListsResponse extends IPaginatedResponse {
  docs: IAdmin[];
}

interface IAdminListFilter {
  search?: string;
  page: number;
  [index: string]: any;
}

export const useSubAdminList = (filters?: IAdminListFilter) =>
  useQuery<IUserListsResponse, Error>(
    [ADMIN_LIST, { ...filters }],
    async () => {
      try {
        const resp = await privateRequest.get(`subAdmin/list`, {
          params: {
            ...filters,
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );

export const addAdmin = async (data: IAdminCreate) => {
  try {
    await privateRequest.post(`subAdmin/create`, data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export interface IAdminUpdate extends Partial<IAdminCreate> {
  userId: string;
}
export const updateAdmin = async (data: IAdminUpdate): Promise<any> => {
  try {
    await privateRequest.post(`subAdmin/update/${data.userId}`, {
      name: data.name,
      email: data.email,
      password: data.password,
    });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const deleteAdmin = async (userId: string) => {
  try {
    await privateRequest.post(`subAdmin/delete`, { userId });
  } catch (error) {
    axiosErrorHandler(error);
  }
};
