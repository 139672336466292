const SpaceBar = {
  SectionGap: () => {
    return <div className="h-10"></div>;
  },
  TitleGap: () => {
    return <div className="h-4"></div>;
  },
};

export default SpaceBar;
