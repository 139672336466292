import { Alert, Backdrop, CircularProgress, colors } from "@mui/material";
import ResponsiveDrawer, { BackButtonLinkType } from "components/layout/Drawer";
import { AuthContext, IUser } from "context/AuthContext";
import { useUser } from "queries/auth";
import React, { FC, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";

type RequireAuthProps = {
  backButtonLink?: BackButtonLinkType;
};

const RequireAuth: FC<RequireAuthProps> = ({ backButtonLink }) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const user = useUser((data: IUser) => auth.setUser(data));

  if (user.isLoading) {
    return (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (user.isError) {
    return (
      <div>
        <Alert
          severity="error"
          sx={{ background: colors.yellow[700], fontSize: 24 }}
        >
          Something went wrong
        </Alert>
      </div>
    );
  }

  return (
    <ResponsiveDrawer backButtonLink={backButtonLink}>
      <Outlet />
    </ResponsiveDrawer>
  );
};

export default RequireAuth;
