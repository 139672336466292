import { BiGame, BiGitPullRequest } from "react-icons/bi";
import { BsCoin } from "react-icons/bs";
import { FaHandsHelping, FaRegListAlt, FaUserTie } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";
import { HiDocumentReport, HiOutlineDocumentReport } from "react-icons/hi";
import { IoCashOutline, IoGameController } from "react-icons/io5";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineManageAccounts,
} from "react-icons/md";
import { RiAdminFill, RiDashboardLine } from "react-icons/ri";
import { RouteLinks } from "./routeLinks";

export interface IDrawerChild {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  link: string;
}

export interface IDrawerLink {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  link?: string;
  child?: IDrawerChild[];
}

export const drawerLinks: IDrawerLink[] = [
  {
    label: RouteLinks.DASHBOARD.label,
    icon: RiDashboardLine,
    link: RouteLinks.DASHBOARD.link,
  },
  {
    label: "Admin Management",
    icon: RiAdminFill,
    link: "/user/admin-management",
  },
  {
    label: "Withdrawal",
    icon: IoCashOutline,
    link: "/user/withdraw",
    child: [
      {
        label: "History",
        icon: FaRegListAlt,
        link: "/user/withdraw/history",
      },
      {
        label: "Resolution Center",
        icon: FaHandsHelping,
        link: "/user/withdraw/resolution",
      },
    ],
  },
  {
    label: "Agent Management",
    icon: FaUserTie,
    link: "/user/agent-management",
  },
  {
    label: RouteLinks.USER_MANAGEMENT.label,
    icon: MdOutlineManageAccounts,
    link: RouteLinks.USER_MANAGEMENT.link,
  },
  {
    label: "Game Management",
    icon: IoGameController,
    link: "/user/game/manage",
    child: [
      {
        label: "Game List",
        icon: BiGame,
        link: "/user/game/manage",
      },
      {
        label: "Game Reports",
        icon: HiDocumentReport,
        link: "/user/game/reports",
      },
    ],
  },
  {
    label: "GC Management",
    icon: BsCoin,
    child: [
      {
        label: "Transfer",
        icon: FaRegListAlt,
        link: "/user/gc/transfer",
      },
      {
        label: "Pending Requests",
        icon: BiGitPullRequest,
        link: "/user/gc/requests",
      },
    ],
  },
  {
    label: "Reports",
    icon: HiOutlineDocumentReport,
    child: [
      {
        label: RouteLinks.AGENT_COMMISSION.label,
        icon: GiProfit,
        link: "/user/reports/" + RouteLinks.AGENT_COMMISSION.link,
      },
      {
        label: RouteLinks.PLAYER_BALANCE.label,
        icon: MdOutlineAccountBalanceWallet,
        link: "/user/reports/" + RouteLinks.PLAYER_BALANCE.link,
      },
    ],
  },
  {
    label: "Transaction History",
    icon: FaRegListAlt,
    link: "/user/transaction-history",
  },
];

export const drawerLinksForSubadmin: IDrawerLink[] = [
  {
    label: RouteLinks.DASHBOARD.label,
    icon: RiDashboardLine,
    link: RouteLinks.DASHBOARD.link,
  },

  {
    label: "Transaction History",
    icon: FaRegListAlt,
    link: "/user/transaction-history",
  },
];
