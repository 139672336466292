import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export const DASHBOARD_STATS = "DASHBOARD_STATS";

export type IDashboradStateResp = {
  members: {
    total: number;
    agent: {
      total: number;
      ae: number;
      jam: number;
      sam: number;
      eam: number;
    };
    player: number;
  };
  totalGameCredit: number;
  gcCommission: {
    total: number;
    ae: number;
    jam: number;
    sam: number;
    eam: number;
    player: number;
  };
  gameCreditLosses: {
    total: number;
    ae: number;
    jam: number;
    sam: number;
    eam: number;
    player: number;
  };
  gameCreditWins: {
    total: number;
    ae: number;
    jam: number;
    sam: number;
    eam: number;
    player: number;
  };
  totalGcTransfer: number;
};

const fetchDashboardStat = async () => {
  try {
    const resp = await privateRequest.get("dashboard?period=all");
    return resp.data?.data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const useDashboardStat = () =>
  useQuery<IDashboradStateResp, Error>(DASHBOARD_STATS, fetchDashboardStat);
