import LoadingButton from "@mui/lab/LoadingButton";
import { AuthContext } from "context/AuthContext";
import {
  IChat,
  IMessage,
  sendMessage,
  useMessages,
} from "queries/transaction-history";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { useMutation } from "react-query";
import { Socket } from "socket.io-client";
import Message from "./Messge";

type Props = {
  disabled?: boolean;
  id: string;
  socket: Socket;
};

const ChatBox = ({ disabled, id, socket }: Props) => {
  const { user } = useContext(AuthContext);
  const lastMessageRef = React.useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState<IChat[]>([]);

  const SendMessage = useMutation<any, Error, IMessage>(sendMessage, {
    onSuccess: () => {
      setMessage("");
    },
  });

  const { isLoading, error, data: messages } = useMessages(id);
  const messageList = [...(messages?.docs || [])].reverse();

  useEffect(() => {
    setAllMessages(messages?.docs || []);
  }, [messages]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageList]);

  useEffect(() => {
    socket.emit("joinRoom", { chatroomId: id });
    // ws for detail data update
    socket.on(`newMessage-${id}`, (newMessage: IChat) => {
      console.log("listenting chat event=>", newMessage);
      setAllMessages([...allMessages, newMessage]);
      lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });

      // // update cache with latest data
      // queryClient.setQueryData<IChatRespons>(
      //   ["chat-messages", id],
      //   (oldData) => {
      //     if(oldData)
      //   }
      // );
    });
  }, [socket, id, allMessages]);

  const onSendMessage: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    SendMessage.mutate({
      conversationId: id,
      message: message,
    });
  };

  return (
    <div className="p-8 bg-[#F4F4F4] rounded-lg">
      <div className="flex items-center gap-2 mb-4">
        <AiOutlineMessage size={30} />
        <p className="text-lg font-semibold">Chat</p>
      </div>

      <div className="bg-[#FBFBFD] p-3 rounded-lg max-h-[400px] overflow-y-auto flex flex-col gap-y-4">
        {allMessages.map((msg) => (
          <Message
            message={msg.content}
            timestamp={"jun 20, 2021, 10:10 AM"}
            username={msg.sender.username}
            self={user?.data?._id === msg.sender._id}
            ref={lastMessageRef}
            key={msg._id}
          />
        ))}
      </div>

      {!disabled && socket.connected && (
        <div className="mt-3">
          <form onSubmit={onSendMessage}>
            <textarea
              placeholder="Write your message here "
              rows={5}
              className="w-full rounded-xl p-2 ring-2 ring-transparent focus:ring-primary"
              onChange={(e) => setMessage(e.currentTarget.value)}
              value={message}
            ></textarea>
            <div className="flex gap-x-2">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ width: 150 }}
                loading={SendMessage.isLoading}
              >
                Send
              </LoadingButton>
              {/* <LoadingButton
                variant="text"
                startIcon={<GrAttachment />}
                loading={SendMessage.isLoading}
              >
                Attach File
              </LoadingButton> */}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
