import { TableCell, TablePagination, TableRow } from "@mui/material";
import CustomTable from "components/table/Table";
import { useTransactionHistory } from "queries/transaction-history";
import React, { useState } from "react";
import { getDateFormated } from "utils";
import GCTransferForm from "./components/GCTransferForm";

type Props = {};

const GCTransfer = (props: Props) => {
  const [page, setPage] = useState<number>(0);

  const { isLoading, error, data, isRefetching } = useTransactionHistory({
    page: page + 1,
    filter: {
      criteria: "transfer",
    },
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <div>
      <GCTransferForm />
      <CustomTable
        columns={["Timestamp", "User Name", "Amount (GC)"]}
        isLoading={isLoading || isRefetching}
        skeletonLength={10}
        error={error && "error occured"}
      >
        {data?.docs.map((row) => (
          <TableRow key={row._id}>
            <TableCell>
              {getDateFormated(row.createdAt, "YYYY-MM-DD HH:mm")}
            </TableCell>
            <TableCell>{row.user.username}</TableCell>
            <TableCell>{row.equivalentAmount} PHP</TableCell>
          </TableRow>
        ))}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        hidden={isLoading}
      />
    </div>
  );
};

export default GCTransfer;
