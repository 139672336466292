import { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, CircularProgress } from "@mui/material";
import EnterPin from "components/modals/EnterPin";
import queryClient from "config/queyClient.config";
import { useSockets } from "context/SocketContext";
import {
  Dispute_WITHDRAW_DETAILS,
  IDisputeDetailResponse,
  IReleaseParams,
  releaseAsset,
  ReleaseToType,
  useDisputeDetails,
} from "queries/transaction-history";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { GiGearHammer } from "react-icons/gi";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { getDateFormated } from "utils";
import ChatBox from "./components/ChatBox";

//! status is pending
// -> chat and details should not be rendered
//! not in dispute
// ? status is confirmed
// -> simple flow view

//? status is declined
// -> simple declined view
//! in dispute

type Props = {};

const DisputeDetail = (props: Props) => {
  const { socket } = useSockets();
  const EnterPinModal = useModal(EnterPin);
  const { id } = useParams<{ id: string }>();

  const { isLoading, error, data, isSuccess } = useDisputeDetails(id);
  /* 
    Release mutation
  */
  const Release = useMutation<any, Error, IReleaseParams>(releaseAsset, {
    onError: (err) => {
      toast.error(err.message);
    },
    onSuccess: () => {
      toast.success("Released Successfully");
      queryClient.invalidateQueries(Dispute_WITHDRAW_DETAILS);
    },
  });

  const onRelease = (to: ReleaseToType) => {
    // handle release
    EnterPinModal.show().then((PIN) => {
      // send pin alogn with request
      Release.mutate({ pin: PIN as string, releaseTo: to, id: id || "" });
    });
  };

  useEffect(() => {
    socket.emit("joinRoom", { chatroomId: id });
    socket.on(
      `withdrawDetails-62b9964d4d21b76436297590`,
      (data: IDisputeDetailResponse) => {}
    );

    // ws for detail data update
    socket.on(`withdrawDetails-${id}`, (data: IDisputeDetailResponse) => {
      console.log("socket working", data);
      // update cache with latest data
      queryClient.setQueryData<IDisputeDetailResponse>(
        [Dispute_WITHDRAW_DETAILS, id],
        {
          ...data,
        }
      );
    });

    return () => {};
  }, [id, socket]);

  /* 
    render basic detail view if it is non disputed transaction
  */
  const nonDisputedView = data?.disputedAt === null;

  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isSuccess && data && nonDisputedView) {
    return (
      <div className="p-8 bg-[#F4F4F4] rounded-lg">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-xs md:text-base text-[#525252]">Timestamp</p>
            <p className="text-xs md:text-base font-medium">
              {getDateFormated(data.createdAt)}
            </p>
          </div>
          <div>
            <p className="text-xs md:text-base text-[#525252]">Amount</p>
            <p className="text-xs md:text-base font-medium">
              {data.amount} {data.currencyCode}
            </p>
          </div>
          <div>
            <p className="text-xs md:text-base text-[#525252]">TxID</p>
            <p className="text-xs md:text-base font-medium">{data.txid}</p>
          </div>
          <div>
            <p className="text-xs md:text-base text-[#525252]">Player name</p>
            <p className="text-xs md:text-base font-medium">
              {data.user.username}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-xs md:text-base text-[#525252]">Payment Method</p>
          <p className="text-xs md:text-base font-medium">
            <span className="text-primary">{data.paymentMethod.title} - </span>
            {data.paymentMethod.description}
          </p>
        </div>
      </div>
    );
  }

  if (isSuccess && data) {
    return (
      <div>
        <div className="grid grid-cols-1 md:grid-cols-8 gap-6">
          <div className="md:col-span-5 flex flex-col gap-6">
            {/* overview */}
            <div className="p-8 bg-[#F4F4F4] rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-xs md:text-base text-[#525252]">
                    Timestamp
                  </p>
                  <p className="text-xs md:text-base font-medium">
                    {getDateFormated(data.createdAt)}
                  </p>
                </div>
                <div>
                  <p className="text-xs md:text-base text-[#525252]">Amount</p>
                  <p className="text-xs md:text-base font-medium">
                    {data.amount} {data.currencyCode}
                  </p>
                </div>
                <div>
                  <p className="text-xs md:text-base text-[#525252]">TxID</p>
                  <p className="text-xs md:text-base font-medium">
                    {data.txid}
                  </p>
                </div>
                <div>
                  <p className="text-xs md:text-base text-[#525252]">
                    Player name
                  </p>
                  <p className="text-xs md:text-base font-medium">
                    {data.user.username}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-xs md:text-base text-[#525252]">
                  Payment Method
                </p>
                <p className="text-xs md:text-base font-medium">
                  <span className="text-primary">
                    {data.paymentMethod.title} -{" "}
                  </span>
                  {data.paymentMethod.description}
                </p>
              </div>
            </div>
            {/* details */}
            <div className="p-8 bg-[#F4F4F4] rounded-lg flex flex-col gap-8">
              <InfoCard
                heading={`Agent Sent the payment to @${data?.user?.username}`}
                icon={<FcApproval size={28} />}
              >
                <p className="bg-green-100 text-green-600 p-3 text-center font-semibold text-xl rounded-xl">
                  Payment Sent
                </p>
              </InfoCard>

              <InfoCard
                heading="Player has disputed this transaction."
                icon={<GiGearHammer size={28} color="red" />}
              >
                <p className="bg-red-100 text-red-600 p-3 text-center font-semibold text-xl rounded-xl">
                  Disputed
                </p>
              </InfoCard>

              {data?.withdrawResolvedTo === "none" ? (
                /* //! render button to release  */
                <InfoCard
                  heading="Decesion In Pending"
                  desc="Please decide and release "
                  icon={
                    <AiOutlineLoading3Quarters
                      size={28}
                      className="animate-spin"
                    />
                  }
                >
                  <div className="flex items-center gap-4">
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      loading={Release.isLoading}
                      disabled={Release.isLoading}
                      onClick={() => onRelease("sender")}
                    >
                      Release to Agent
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      loading={Release.isLoading}
                      disabled={Release.isLoading}
                      onClick={() => onRelease("receiver")}
                    >
                      Release to Player
                    </LoadingButton>
                  </div>
                </InfoCard>
              ) : (
                <div className="flex flex-col gap-y-4">
                  <InfoCard
                    heading={`Released to ${
                      data.withdrawResolvedTo === "sender" ? "Agent" : "Player"
                    }`}
                    desc={`Admin released this dispute to  ${
                      data.withdrawResolvedTo === "sender" ? "Agent" : "Player"
                    }`}
                    icon={<FcApproval size={28} />}
                  />
                  <InfoCard
                    heading={`${data?.amount} ${
                      data.currencyCode
                    } Transfered to ${
                      data.withdrawResolvedTo === "sender" ? "Agent" : "Player"
                    }`}
                    icon={<FcApproval size={28} />}
                  />
                </div>
              )}
            </div>
          </div>

          {/* chat */}

          <div className="md:col-span-3 ">
            <ChatBox
              disabled={data.withdrawResolvedTo !== "none"}
              id={id || ""}
              socket={socket}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default DisputeDetail;

interface IInfoCardProps {
  heading: string;
  desc?: string;
  icon?: React.ReactElement;
  children?: React.ReactNode;
}
const InfoCard = ({ heading, icon, desc, children }: IInfoCardProps) => {
  return (
    <div className="">
      <div className={`flex items-center gap-4 ${children && "mb-4"}`}>
        {icon}
        <div className="">
          <p className="text-xl font-medium">{heading}</p>
          {desc && <p className="text-base text-[#525252]">{desc}</p>}
        </div>
      </div>
      {children}
    </div>
  );
};
