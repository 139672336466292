import { UserRoleTypes } from "context/AuthContext";

interface IRouteObjectTypes {
  [key: string]: {
    link: string;
    label: string;
  };
}
const AllRoutes = <T extends IRouteObjectTypes>(obj: T) => obj;

interface IRouteGroupObjectTypes {
  [key: string]: {
    link: UserRoleTypes;
    name: UserRoleTypes;
  };
}
const RouteGroupsGenerate = <T extends IRouteGroupObjectTypes>(obj: T) => obj;

export const RouteGroups = RouteGroupsGenerate({
  USER: {
    name: "user",
    link: "user",
  },
  AGENT: {
    name: "agent",
    link: "agent",
  },
});

export const RouteLinks = AllRoutes({
  DASHBOARD: {
    link: "dashboard",
    label: "Dashboard",
  },
  USER_MANAGEMENT: {
    link: "user-management",
    label: "User Management",
  },
  WALLETS: {
    link: "wallets",
    label: "Wallets",
  },
  GAME_CREDIT_REQUEST: {
    link: "game-credit-request",
    label: "Game Credit Request",
  },
  DEPOSIT: {
    link: "deposit",
    label: "Deposit",
  },
  PROFILE: {
    link: "profile",
    label: "Profile",
  },
  MY_COMMISSION: {
    link: "my-commission",
    label: "My Commission",
  },
  LOGIN: {
    link: "login",
    label: "Login",
  },
  FORGOT_PASSWORD: {
    link: "forgot-password",
    label: "Forgot Password",
  },
  RESET_PASSWORD: {
    link: "reset-password",
    label: "Reset Password",
  },
  TRANSACTION_HISTORY: {
    link: "transaction-history",
    label: "Transaction History",
  },
  LIVE_CASINO: {
    link: "live-casino",
    label: "Live Casino",
  },
  ONLINE_CASINO: {
    link: "online-casino",
    label: "Online Casino",
  },
  LOTTERY: {
    link: "lottery",
    label: "Lottery",
  },
  SPORT: {
    link: "sports",
    label: "Sports",
  },
  VIRTUAL_GAMES: {
    link: "virtual-games",
    label: "Virtual Games",
  },
  TOOLS: {
    link: "tools",
    label: "Tools",
  },
  SUPPORT: {
    link: "support",
    label: "Support",
  },
  FAQ: {
    link: "faq",
    label: "FAQ",
  },
  SWAP: {
    link: "swap",
    label: "Swap",
  },
  PLAYER_MANAGEMENT: {
    link: "player-management",
    label: "Player Management",
  },
  AGENT_MANAGEMENT: {
    link: "agent-management",
    label: "Agent Management",
  },
  AGENT_USERS_ACTIVITY: {
    link: "agent-users-activity",
    label: "Agent Users Activity",
  },
  AGENT_UPGRADE_REQUESTS: {
    link: "agent-upgrade-requests",
    label: "Agent Upgrade Request",
  },
  AGENT_UPGRADE_HISTORY: {
    link: "agent-upgrade-history",
    label: "Agent Upgrade History",
  },
  WITHDRAWAL_MANAGEMENT: {
    link: "withdrawal-management",
    label: "Withdrawal Management",
  },
  WITHDRAW_REQUEST: {
    link: "/user/withdraw-request",
    label: "Withdraw Request",
  },
  PENDING_WITHDRAWALS: {
    link: "pending-withdrawals",
    label: "Pending Withdrawals",
  },
  GC_MANAGEMENT: {
    link: "gc-management",
    label: "GC Management",
  },
  PLAYER_BALANCE: {
    link: "player-balance",
    label: "Player Balance",
  },
  AGENT_COMMISSION: {
    link: "agent-commission",
    label: "Agent Commission",
  },
  RANK: {
    link: "rank",
    label: "Rank",
  },
});
