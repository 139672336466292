import { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  IconButton,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import CustomBackdrop from "components/loader/CustomBackdrop";
import AddAdminModal from "components/modals/AddAdminModal";
import ConsentModal from "components/modals/ConsentModal";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import queryClient from "config/queyClient.config";
import { AuthContext } from "context/AuthContext";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import {
  ADMIN_LIST,
  deleteAdmin,
  IAdmin,
  useSubAdminList,
} from "queries/management/adminManagement.queries";
import { useContext } from "react";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { useMutation } from "react-query";
import { ROWS_PER_PAGE } from "routes/user/management";
import { getDateFormated } from "utils";

type Props = {};

const AdminManagement = (props: Props) => {
  const AddAdmin = useModal(AddAdminModal);

  const { user: CurrentUser } = useContext(AuthContext);
  const [page, setPage] = usePaging();
  const [searchKey, debouncedSearchKey, setDebounsedSearchKey] =
    useDebounceSearch();

  const { isLoading, error, data, isRefetching } = useSubAdminList({
    page: page + 1,
    search: debouncedSearchKey,
  });

  const addAdminHandler = () => {
    AddAdmin.show();
  };

  const isSuperAdmin = CurrentUser?.data?.role === "superAdmin";

  return (
    <div>
      <SectionTitle.Default title="Admin Management">
        <div className="flex gap-4 flex-wrap">
          {isSuperAdmin && (
            <Button variant="contained" onClick={addAdminHandler}>
              Add sub Admin
            </Button>
          )}
          <TextField
            onChange={setDebounsedSearchKey}
            disabled={isLoading}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <IconButton>
                  <BiSearch />
                </IconButton>
              ),
            }}
            placeholder="Search"
            value={searchKey}
          />
        </div>
      </SectionTitle.Default>

      <CustomTable
        columns={["Joined", "Name", "Action"]}
        isLoading={isLoading || isRefetching}
        skeletonLength={10}
        error={error && "error occured"}
      >
        {data?.docs.map((user) => (
          <TableRow key={user._id}>
            <TableCell>
              {getDateFormated(user.createdAt, "YYYY-MM-DD HH:mm")}
            </TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>
              {isSuperAdmin && <SuperAdminAction user={user} />}
            </TableCell>
          </TableRow>
        ))}
        {data?.docs.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No user Found.</p>
        )}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={setPage}
        hidden={isLoading}
      />
    </div>
  );
};

export default AdminManagement;

export const SuperAdminAction = ({ user }: { user: IAdmin }) => {
  const Consent = useModal(ConsentModal);
  const UpdateAdmin = useModal(AddAdminModal);

  const DeleteAdminMutation = useMutation<any, Error, any>(deleteAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(ADMIN_LIST);
      toast.success("Sub Admin Deleted successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleEditBtn = () => {
    UpdateAdmin.show({
      name: user.name,
      email: user.email,
      username: user.username,
      userId: user._id,
    });
  };
  const handleDeleteBtn = () => {
    // ask for consent with modal
    Consent.show({ heading: `Delete Admin ${user.username}` }).then((res) => {
      if (res === "yes") {
        DeleteAdminMutation.mutate(user._id);
      }
    });
  };

  const isLoading = DeleteAdminMutation.isLoading;

  return (
    <div className="flex gap-2 flex-wrap">
      {isLoading && <CustomBackdrop />}
      <Button
        variant="text"
        size="small"
        startIcon={<FiEdit2 />}
        onClick={handleEditBtn}
      >
        Edit
      </Button>
      <Button
        variant="text"
        size="small"
        sx={{ color: "red" }}
        startIcon={<AiFillDelete />}
        onClick={handleDeleteBtn}
      >
        Delete
      </Button>
    </div>
  );
};
