import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { IPaginatedResponse } from "types";
import { axiosErrorHandler } from "utils/errorHandler";
import { Ranks } from "utils/getRank";

export const PLAYER_BALANCE = "PLAYER_BALANCE";

interface IPlayerBalanceList extends IPaginatedResponse {
  docs: IBalance[];
}

interface IBalance {
  user?: {
    _id: string;
    name: string;
    username: string;
    photo: string;
    rank: "ae" | "eam" | "jam" | "sam" | null;
    email: string;
  };
  currencyCode: string;
  total: number;
}

interface IPlayerBalanceFilter {
  search?: string;
  page?: number;
}

export const usePlayerBalanceQuery = ({
  page = 1,
  ...rest
}: IPlayerBalanceFilter = {}) =>
  useQuery<IPlayerBalanceList, Error>(
    [PLAYER_BALANCE, page, { ...rest }],
    async () => {
      try {
        const resp = await privateRequest.get(`report/balance`, {
          params: {
            page: page,
            ...rest,
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );

interface IAgentCommissionList extends IPaginatedResponse {
  docs: ICommission[];
}

interface ICommission {
  commissionCount: number;
  user: string;
  rank: Ranks | null;
  totalCommission: number;
  currentCommission: number;
}

interface IAgentCommissionFilter {
  search?: string;
  page?: number;
  rank?: Ranks | "";
}

export const useAgentCommission = ({
  page = 1,
  ...rest
}: IAgentCommissionFilter = {}) =>
  useQuery<IAgentCommissionList, Error>(
    ["fetch-agent-commission", page, { ...rest }],
    async () => {
      try {
        const resp = await privateRequest.get(`report/agentCommission`, {
          params: {
            page: page,
            ...rest,
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
