import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().when("password", {
    is: (password: string) => !password || password.length === 0,
    then: yup.string().email().required("Edit at least one field to update"),
  }),
  password: yup.string(),
});
export type IUserEditForm = yup.InferType<typeof schema>;

interface IProps {
  email: string;
  [index: string]: any;
}

const EditUserInfoModal = NiceModal.create<IProps>(({ email }) => {
  const modal = useModal();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserEditForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email,
      password: undefined,
    },
  });

  const onSubmit: SubmitHandler<IUserEditForm> = (data) => {
    modal.resolve(data);
    modal.remove();
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <form
          className="flex flex-col gap-y-4 w-72"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <p className="mb-2">Email</p>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div>
            <p className="mb-2">Password</p>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  InputProps={{ disableUnderline: true }}
                  value={value}
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            // onClick={() => {
            //   modal.resolve("yes");
            //   modal.remove();
            // }}
          >
            Update
          </Button>
          {errors.email && (
            <Alert severity="error">{errors.email.message}</Alert>
          )}
          {errors.password && (
            <Alert severity="error">{errors.password.message}</Alert>
          )}
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
});

export default EditUserInfoModal;
