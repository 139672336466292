import {
  FormControl,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import { SectionTitle } from "components/title";
import { debounce } from "lodash";
import { useDropdownFilter } from "queries/filter";
import { useTransactionHistory } from "queries/transaction-history";
import React, { useRef, useState } from "react";
import TransactionTable from "./components/TransactionTable";

const ranks = ["ae", "jam", "sam", "eam"];
const criterias = ["commission", "withdraw", "reward", "purchase", "transfer"];

interface IFilters {
  rank: string;
  criteria: string;
  fromDate: null | Date;
  toDate: null | Date;
}

type Props = {};

const TransactionHistory = (props: Props) => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<IFilters>({
    rank: "none",
    criteria: "none",
    fromDate: null,
    toDate: null,
  });
  const [searchKey, setSearchKey] = useState("");
  const [debounsedSearchKey, setDebounsedSearchKey] = useState("");
  const debouncedKey = useRef(
    debounce((value) => setDebounsedSearchKey(value), 600)
  );

  const dowpdownFilters = useDropdownFilter();
  const { data, isLoading, error } = useTransactionHistory({
    page: page + 1,
    filter: { ...filters, search: debounsedSearchKey },
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <div>
      <SectionTitle.Default title="Transaction History">
        {/* filters */}
        <div className="flex items-center gap-3 flex-wrap justify-center">
          <FormControl>
            <p className="text-xs text-gray-200">Rank</p>
            <Select
              value={filters.rank}
              onChange={(e) => setFilters({ ...filters, rank: e.target.value })}
              label="Rank"
              disabled={dowpdownFilters.isLoading}
            >
              <MenuItem value="none">All Ranks</MenuItem>
              {dowpdownFilters.data?.rank.map((rank) => (
                <MenuItem value={rank}>{rank}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">Criteria</p>
            <Select
              value={filters.criteria}
              onChange={(e) =>
                setFilters({ ...filters, criteria: e.target.value })
              }
              label="Rank"
              disabled={dowpdownFilters.isLoading}
            >
              <MenuItem value="none">All Criteria</MenuItem>
              {dowpdownFilters.data?.criteria.map((criteria) => (
                <MenuItem value={criteria}>{criteria}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <p className="text-xs text-gray-200">From</p>
            <DatePicker
              value={filters.fromDate}
              onChange={(value: Date | null) => {
                setFilters({ ...filters, fromDate: value });
              }}
            />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">To</p>
            <DatePicker
              value={filters.toDate}
              onChange={(value) => setFilters({ ...filters, toDate: value })}
            />
          </FormControl>
          <FormControl>
            <p className="text-xs text-gray-200">Search</p>
            <TextField
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
                debouncedKey.current(e.target.value);
              }}
            />
          </FormControl>
        </div>
      </SectionTitle.Default>

      <TransactionTable
        docs={data?.docs || []}
        isLoading={isLoading}
        error={error?.message}
      />
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        hidden={isLoading}
      />
    </div>
  );
};

export default TransactionHistory;
