import {
  Chip,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import { usePlayerBalanceQuery } from "queries/reports/player_balance_query";
import { getRank } from "utils/getRank";

type Props = {};

const COLUMNS = ["Username", "Rank", "Balance"];

const PlayerBalancePage = (props: Props) => {
  const [page, setPage] = usePaging();
  const [search, debouncedSearch, setSearch] = useDebounceSearch();
  const { isLoading, error, data } = usePlayerBalanceQuery({
    page: page + 1,
    search: debouncedSearch,
  });

  return (
    <div>
      <SectionTitle.Default title="Player Balance">
        <TextField value={search} onChange={setSearch} placeholder="Search" />
      </SectionTitle.Default>
      <CustomTable
        columns={COLUMNS}
        isLoading={isLoading}
        error={error?.message}
      >
        {data?.docs?.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.user?.username}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <img
                  src={getRank(row.user?.rank ?? null)}
                  alt={`rank-icon-${row.user?.rank}`}
                  loading="lazy"
                  className="w-10 hover:scale-150"
                />
                <Chip
                  label={row.user?.rank || "player"}
                  color="info"
                  sx={{ textTransform: "uppercase" }}
                />
              </div>
            </TableCell>
            <TableCell>{row.total.toFixed(2)} PHP</TableCell>
          </TableRow>
        ))}
        {data?.docs.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No Data Found.</p>
        )}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={setPage}
        hidden={isLoading}
      />
    </div>
  );
};

export default PlayerBalancePage;
