import { Alert, Skeleton, Typography } from "@mui/material";
import { OverviewCard } from "components/card";
import { useDashboardStat } from "queries/dashbaord/dashboard.queries";
import React, { FC } from "react";

const Overviews: FC = () => {
  const { data, isLoading, error } = useDashboardStat();

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {[1, 2, 3, 4, 6, 5].map((_, i) => (
          <Skeleton
            variant="rectangular"
            height={180}
            width="100%"
            key={i}
            sx={{ borderRadius: 1 }}
          />
        ))}
      </div>
    );
  }

  if (error) {
    <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      <OverviewCard
        index={0}
        title="Total Users"
        number={`${data?.members?.total || 0}`}
      >
        <ul className="mb-6 overflow-hidden">
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              EAM
              <span className="float-right">{data?.members.agent.eam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              SAM
              <span className="float-right">{data?.members.agent.sam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              JAM
              <span className="float-right">{data?.members.agent.jam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              AE
              <span className="float-right">{data?.members.agent.ae}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              Players
              <span className="float-right">{data?.members.player}</span>
            </Typography>
          </li>
        </ul>
      </OverviewCard>
      <OverviewCard
        index={1}
        title="Total Game Credit"
        number={`${data?.totalGameCredit} PHP`}
      />
      <OverviewCard
        index={2}
        title="Total GC Commissions "
        number={`${data?.gcCommission.total.toFixed(2)} PHP`}
      >
        <ul className="mb-6 overflow-hidden">
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              EAM
              <span className="float-right">{data?.gcCommission.eam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              SAM
              <span className="float-right">{data?.gcCommission.sam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              JAM
              <span className="float-right">{data?.gcCommission.jam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              AE
              <span className="float-right">{data?.gcCommission.ae}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              Players
              <span className="float-right">{data?.gcCommission.player}</span>
            </Typography>
          </li>
        </ul>
      </OverviewCard>
      <OverviewCard
        index={3}
        title="Total Game Credit Losses "
        number={`${data?.gameCreditLosses.total.toFixed(2)} PHP`}
      >
        <ul className="mb-6 overflow-hidden">
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              EAM
              <span className="float-right">{data?.gameCreditLosses.eam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              SAM
              <span className="float-right">{data?.gameCreditLosses.sam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              JAM
              <span className="float-right">{data?.gameCreditLosses.jam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              AE
              <span className="float-right">{data?.gameCreditLosses.ae}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              Players
              <span className="float-right">
                {data?.gameCreditLosses.player}
              </span>
            </Typography>
          </li>
        </ul>
      </OverviewCard>
      <OverviewCard
        index={4}
        title="Total Game Credit Wins"
        number={`${data?.gameCreditWins.total.toFixed(2)}`}
      >
        <ul className="mb-6 overflow-hidden">
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              EAM
              <span className="float-right">{data?.gameCreditWins.eam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              SAM
              <span className="float-right">{data?.gameCreditWins.sam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              JAM
              <span className="float-right">{data?.gameCreditWins.jam}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              AE
              <span className="float-right">{data?.gameCreditWins.ae}</span>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              Players
              <span className="float-right">{data?.gameCreditWins.player}</span>
            </Typography>
          </li>
        </ul>
      </OverviewCard>
      <OverviewCard
        index={5}
        title="Total GC Transfer"
        number={`${data?.totalGcTransfer.toFixed(2)} PHP`}
      />
    </div>
  );
};
export default React.memo(Overviews);
