import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const DeclineModal = NiceModal.create(() => {
  const [note, setNote] = useState("");
  const modal = useModal();
  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogContent>
        <p className="text-gray-400 mb-2">Rejection Note</p>
        <TextField
          multiline
          rows={4}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            modal.resolve(note);
            modal.remove();
          }}
        >
          Reject
        </Button>
        <Button variant="outlined" onClick={() => modal.remove()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DeclineModal;
