import { IUserEditForm } from "components/modals/EditUserInfoModal";
import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { IPaginatedResponse } from "types";
import { formatFilters } from "utils";
import { axiosErrorHandler } from "utils/errorHandler";
import { Ranks } from "utils/getRank";

export const USER_LISTS = "USER_LISTS";

export interface IUserResponse {
  _id: string;
  name: string;
  username: string;
  country: string;
  photo?: any;
  role: string;
  isEmailVerified: boolean;
  phone?: any;
  dialCode?: any;
  isPhoneVerified: boolean;
  googleAuthentication: boolean;
  status: "pending" | "activated" | "blocked";
  sponsor: string;
  email: string;
  referralId: string;
  createdAt: string;
  rank: Ranks | null;
  upgradableRanks: Ranks[];
}

export interface IUserListsResponse extends IPaginatedResponse {
  docs: IUserResponse[];
}

interface IUserListFilter {
  search?: string;
  role?: string;
  page: number;
  [index: string]: any;
}
export const useUserList = (filters: IUserListFilter) => {
  return useQuery<IUserListsResponse, Error>(
    [USER_LISTS, { ...filters }],
    async () => {
      try {
        const normalizedFilters = formatFilters(filters);
        normalizedFilters.page = normalizedFilters.page || 1;
        const resp = await privateRequest.get("users/list", {
          params: {
            ...normalizedFilters,
          },
        });
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

export const blockUser = async (userId: string): Promise<any> => {
  try {
    await privateRequest.post("users/block", { userId: userId });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const unblockUser = async (userId: string): Promise<any> => {
  try {
    await privateRequest.post("users/unblock", { userId: userId });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const editUser = async (
  data: IUserEditForm & { id: string }
): Promise<any> => {
  try {
    await privateRequest.patch("users/update", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

interface IUpgradeAgentParams {
  userId: string;
  rank: Ranks;
}
export const upgradeAgent = async (data: IUpgradeAgentParams) => {
  try {
    await privateRequest.patch(`/users/upgrade`, data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};
