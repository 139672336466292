import { Card, Grow, Theme, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface Props {
  children?: React.ReactNode;
  padding?: string;
  bgColor?: string;
  borderRadius?: string;
  boxShadow?: string;
  className?: string;
}

export default function CardUI({
  children,
  padding,
  bgColor,
  borderRadius,
  boxShadow,
  className,
}: Props) {
  const isMobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const theme = useTheme();

  const responsivePadding = padding ?? (isMobileScreen ? 2 : 3);
  return (
    <Grow in>
      <Card
        elevation={2}
        className={className}
        sx={{
          p: responsivePadding,
          borderRadius: borderRadius ? borderRadius : "12px",
          background: bgColor ? bgColor : theme.palette.background.paper,
        }}
      >
        {children}
      </Card>
    </Grow>
  );
}
