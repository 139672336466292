import _ from "lodash";
import { useRef, useState } from "react";

const useDebounceSearch = () => {
  const [value, setValue] = useState("");
  const [debounsedValue, setDebounsedValue] = useState("");

  const debouncedRef = useRef(
    _.debounce((value: string) => setDebounsedValue(value), 600)
  );

  const setSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setValue(value);
    debouncedRef.current(value);
  };

  return [value, debounsedValue, setSearch] as const;
};

export default useDebounceSearch;
