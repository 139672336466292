import AE from "assets/icons/ranks/ae.png";
import EAM from "assets/icons/ranks/eam.png";
import JAM from "assets/icons/ranks/jam.png";
import PLAYER from "assets/icons/ranks/player.png";
import SAM from "assets/icons/ranks/sam.png";

export const ranks = ["ae", "eam", "jam", "sam"] as const;
export type Ranks = typeof ranks[number];

const rankIcons: Record<Ranks, string> = {
  ae: AE,
  jam: JAM,
  sam: SAM,
  eam: EAM,
};

export const getRank = (rank: Ranks | null) => {
  return rank ? rankIcons[rank] : rank === "player" ? undefined : PLAYER;
};
