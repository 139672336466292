import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";

interface ILanguage {
  title: string;
  code: string;
  lang: string;
}

const languages: ILanguage[] = [
  {
    title: "English",
    code: "en",
    lang: "googtrans(en|en)",
  },
  {
    title: "German",
    code: "de",
    lang: "googtrans(en|de)",
  },
  {
    title: "Portuguese",
    code: "pt",
    lang: "googtrans(en|pt)",
  },
  {
    title: "Russian",
    code: "ru",
    lang: "googtrans(en|ru)",
  },
  {
    title: "Spanish",
    code: "es",
    lang: "googtrans(en|es)",
  },
  {
    title: "Thai",
    code: "th",
    lang: "googtrans(en|th)",
  },
  {
    title: "Japanese",
    code: "ja",
    lang: "googtrans(en|ja)",
  },
  {
    title: "Malaysia",
    code: "ms",
    lang: "googtrans(en|ms)",
  },
  {
    title: "Chinese",
    code: "zh-CN",
    lang: "googtrans(en|zh-CN)",
  },
  {
    title: "Philipines",
    code: "tl",
    lang: "googtrans(en|tl)",
  },
  {
    title: "Indonesia",
    code: "id",
    lang: "googtrans(en|id)",
  },
  {
    title: "India",
    code: "hi",
    lang: "googtrans(en|hi)",
  },
  {
    title: "UAE",
    code: "ar",
    lang: "googtrans(en|ar)",
  },
];

function readCookie(name: string) {
  var c = document.cookie.split("; "),
    cookies: any = {},
    i,
    C;

  for (i = c.length - 1; i >= 0; i--) {
    C = c[i].split("=");
    cookies[C[0]] = C[1];
  }

  return cookies[name];
}

const LanguageSwitcher = () => {
  const [language, setLang] = useState<ILanguage | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const langChangeHandler = (lang: ILanguage) => {
    setLang(lang);
    handleClose();
  };

  const currentLang = readCookie("langcode") ?? null;

  useEffect(() => {
    if (language) {
      var lang = language?.lang
        .replace("googtrans(", "/")
        .replace(")", "")
        .replace("|", "/");
      document.cookie = "googtrans=" + lang;
      document.cookie = "googtrans=" + lang + ";domain=localhost:3000;path=/";
      document.cookie = "googtrans=" + lang + ";domain=.kryptobet.net;path=/";
      document.cookie =
        "googtrans=" + lang + ";domain=user-staging.kryptobet.net;path=/";
      document.cookie = "langname=" + language?.title;
      document.cookie = "langcode=" + language?.code;

      window.location.reload();
    }
  }, [language]);

  return (
    <div className="ml-auto">
      <IconButton
        sx={{
          border: "1px solid #ddd",
          width: "25px",
          p: 0,
          overflow: "hidden",
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
          src={`/img/flags/${currentLang ?? "en"}.svg`}
          alt={currentLang ?? "en"}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languages.map((lang, index) => (
          <MenuItem key={index} onClick={() => langChangeHandler(lang)}>
            <img
              height={20}
              width={20}
              style={{ borderRadius: "50%", objectFit: "cover" }}
              src={`/img/flags/${lang.code}.svg`}
              alt=""
            />
            <span className="ml-2">{lang.title}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
