import { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TableCell, TablePagination, TableRow, TextField } from "@mui/material";
import UserAvatar from "components/avatar/UserAvatar";
import CustomBackdrop from "components/loader/CustomBackdrop";
import ConsentModal from "components/modals/ConsentModal";
import DeclineModal from "components/modals/DeclineModal";
import CustomTable from "components/table/Table";
import { SectionTitle } from "components/title";
import queryClient from "config/queyClient.config";
import useDebounceSearch from "hooks/useDebounceSearch";
import usePaging from "hooks/usePaging";
import {
  approveGcRequest,
  declineGcRequest,
  GC_REQUESTS,
  IGCRequest,
  useGcRequests,
} from "queries/management/gcManagement.queries";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { getDateFormated } from "utils";

type Props = {};

const COLUMNS = [
  "Timestamp",
  "TXID",
  "From User",
  "To User",
  "Amount",
  "Action",
];

const GCRequests = (props: Props) => {
  const [page, setPage] = usePaging();
  const [search, debouncedSearch, setSearch] = useDebounceSearch();
  const { isLoading, error, data } = useGcRequests({
    page: page + 1,
    search: debouncedSearch,
    status: "pending",
  });

  return (
    <div>
      <SectionTitle.Default title="Pending GC Requests">
        <TextField value={search} onChange={setSearch} placeholder="Search" />
      </SectionTitle.Default>
      <CustomTable
        columns={COLUMNS}
        isLoading={isLoading}
        error={error?.message}
      >
        {data?.docs?.map((row) => (
          <TableRow key={row._id}>
            <TableCell>{getDateFormated(row.createdAt)}</TableCell>
            <TableCell>{row.txid}</TableCell>
            <TableCell>
              <UserAvatar
                name={row.issueer.username}
                photo={row.issueer.photo}
              />
            </TableCell>
            <TableCell>
              <UserAvatar name={row.user.username} photo={row.user.photo} />
            </TableCell>
            <TableCell>{row.equivalentAmount} PHP</TableCell>
            <TableCell>
              <Actions {...row} />
            </TableCell>
          </TableRow>
        ))}
        {data?.docs.length === 0 && (
          <p className="text-yellow-600 p-3 text-lg">No Data Found.</p>
        )}
      </CustomTable>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.totalDocs || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={setPage}
        hidden={isLoading}
      />
    </div>
  );
};

export default GCRequests;

const Actions = (props: IGCRequest) => {
  const Approvemodal = useModal(ConsentModal);
  const DeclineModalView = useModal(DeclineModal);

  const DeclineMutation = useMutation<any, Error, any>(declineGcRequest, {
    onError: (err) => {
      toast.error(err.message);
    },
    onSuccess: (err) => {
      toast.success(`Declined GC Request successfully`);
      queryClient.invalidateQueries(GC_REQUESTS);
    },
  });
  const ApproveMutation = useMutation<any, Error, any>(approveGcRequest, {
    onError: (err) => {
      toast.error(err.message);
    },
    onSuccess: (err) => {
      toast.success(`Request approved`);
      queryClient.invalidateQueries(GC_REQUESTS);
    },
  });

  const handleDecline = () => {
    DeclineModalView.show().then((note) => {
      DeclineMutation.mutate({ id: props._id, note: note });
    });
  };
  const handleApprove = () => {
    Approvemodal.show({
      heading: "Approve GC Transfer Request",
      positiveAction: true,
    }).then((res) => {
      if (res === "yes") {
        ApproveMutation.mutate({ id: props._id });
      }
    });
  };

  const actionInProgress =
    DeclineMutation.isLoading || ApproveMutation.isLoading;

  return (
    <div className="flex items-center gap-2">
      {actionInProgress && <CustomBackdrop />}
      <LoadingButton
        variant="contained"
        size="small"
        sx={{ color: "error.success" }}
        onClick={handleApprove}
      >
        Transfer
      </LoadingButton>
      <LoadingButton
        variant="text"
        size="small"
        sx={{ color: "red" }}
        onClick={handleDecline}
      >
        Cancel
      </LoadingButton>
    </div>
  );
};
