import { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import { requestToAddPin } from "queries/auth";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import * as yup from "yup";

const schema = yup.object({
  newPin: yup.string().required("Required"),
  confirmPin: yup
    .string()
    .oneOf([yup.ref("newPin"), null], "Pins don't match.")
    .required("Required"),
});
export type IChangePinForm = yup.InferType<typeof schema>;

interface IPinChangeFormProps {
  setTrace: (traceId: string) => void;
}
const PinChangeForm = ({ setTrace }: IPinChangeFormProps) => {
  const RequestPinMutation = useMutation<{ traceId: string }, Error, any>(
    requestToAddPin,
    {
      onError: (err) => {
        toast.error(err.message);
      },
      onSuccess: (data, v, x) => {
        console.log(data);
        setTrace(data.traceId);
      },
    }
  );


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePinForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IChangePinForm> = (data) => {
    RequestPinMutation.mutate({ pin: data.newPin });
  };

  return (
    <div>
      <form
        className="flex flex-col gap-y-4 w-72"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <p className="mb-2">New pin</p>
          <Controller
            name="newPin"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                fullWidth
                InputProps={{ disableUnderline: true }}
                error={Boolean(errors.newPin)}
                helperText={errors.newPin?.message}
                {...field}
              />
            )}
          />
        </div>
        <div>
          <p className="mb-2">Re-type Pin</p>
          <Controller
            name="confirmPin"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <TextField
                InputProps={{ disableUnderline: true }}
                value={value}
                onChange={onChange}
                error={Boolean(errors.confirmPin)}
                helperText={errors.confirmPin?.message}
                fullWidth
              />
            )}
          />
        </div>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={RequestPinMutation.isLoading}
        >
          Update
        </LoadingButton>
      </form>
    </div>
  );
};

export default PinChangeForm;
