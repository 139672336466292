import { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Switch,
} from "@mui/material";
import { CardUI } from "components/card";
import ChangePasswordModal from "components/modals/ChangePasswordModal";
import ChangePinModal from "components/modals/ChangePinModal";
import { SectionTitle } from "components/title";
import { AuthContext } from "context/AuthContext";
import React, { FC, useContext } from "react";
import { AiOutlineGoogle } from "react-icons/ai";
import { MdOutlineSecurity } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";

type SecurityCardProps = {};

const SecurityCard: FC<SecurityCardProps> = (props) => {
  const { user } = useContext(AuthContext);
  const ChangePasswordView = useModal(ChangePasswordModal);
  const ChangePinView = useModal(ChangePinModal);

  const handlePasswordChange = () => {
    ChangePasswordView.show();
  };

  const handlePinChange = () => {
    ChangePinView.show();
  };

  return (
    <CardUI className="flex flex-col gap-4">
      <SectionTitle.Default title="Security" />

      <div className="flex flex-col gap-2">
        <ListItemButton>
          <ListItemAvatar>
            <MdOutlineSecurity size={32} />
          </ListItemAvatar>
          <ListItemText primary="Password" secondary="Protect your account." />
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handlePasswordChange}
          >
            Change
          </LoadingButton>
        </ListItemButton>

        <Divider />

        <ListItemButton>
          <ListItemAvatar>
            <RiLockPasswordLine size={32} />
          </ListItemAvatar>
          <ListItemText primary="Pin" secondary="Protect your account." />
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handlePinChange}
          >
            {user.data?.hasPin ? "Change" : "Add"}
          </LoadingButton>
        </ListItemButton>

        <Divider />

        <ListItemButton>
          <ListItemAvatar>
            <AiOutlineGoogle size={32} />
          </ListItemAvatar>
          <ListItemText
            primary="Google Authenticator"
            secondary="Protect your account and transections."
          />
          <Switch disabled />
        </ListItemButton>
      </div>
    </CardUI>
  );
};

export default SecurityCard;
