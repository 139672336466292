import { Typography } from "@mui/material";
import React from "react";

type TitleProps = {
  title: string;
  children?: React.ReactNode;
};

function DefaultTitle({ title, children }: TitleProps) {
  return (
    <div className="flex items-center flex-wrap gap-3 justify-between">
      <Typography variant="h3">{title}</Typography>
      {children}
    </div>
  );
}

type SimpleTitleProps = {
  title: string;
  alignment?: "left" | "center" | "right";
};

function SimpleTitle({ title, alignment = "center" }: SimpleTitleProps) {
  return (
    <Typography
      className="font-secondary text-primary-mute text-3xl font-normal"
      component={"h3"}
      variant="h3"
      textAlign={alignment}
      textTransform="capitalize"
    >
      {title}
    </Typography>
  );
}

const SectionTitle = {
  Default: DefaultTitle,
  Simple: SimpleTitle,
};

export default SectionTitle;
