import {
  Alert,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomBackdrop from "components/loader/CustomBackdrop";
import { SectionTitle } from "components/title";
import queryClient from "config/queyClient.config";
import useDebounceSearch from "hooks/useDebounceSearch";
import {
  addToHotList,
  GAME_LIST,
  removeFromHotList,
  useInfiniteGameList,
} from "queries/games";
import { memo, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { BiSearch } from "react-icons/bi";
import { useMutation } from "react-query";

const categories = [
  "livecasino",
  "poker",
  "virtual-sports",
  "sportsbook",
  "live-casino-table",
  "video-slots",
  "table-games",
  "video-poker",
  "virtual-games",
  "scratch-cards",
];

type Props = {};

const GameManagement = (props: Props) => {
  const [category, setCategory] = useState("none");
  const [searchValue, debouncedSearch, setSearchValue] = useDebounceSearch();
  const [tab, setTab] = useState("all");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const reset = () => {
    setSelectedRows([]);
    queryClient.invalidateQueries(GAME_LIST);
  };

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setTab(newValue);
    },
    []
  );

  /* 
    Game list fetch hook
  */
  const { isLoading, error, data, isRefetching } = useInfiniteGameList({
    isHot: tab === "hot" ? "yes" : "",
    type: category,
    search: debouncedSearch,
  });
  const rows = useMemo(
    () =>
      data?.docs
        ? data.docs.map((row, i) => ({
            gameName: row.gamename || row.name,
            category: row.category,
            id: row._id,
          }))
        : [],
    [data?.docs]
  );

  /* add to hotlist mutation 🔥 🌐*/
  const {
    isLoading: loadingAddToHotList,
    error: errorAddToHotList,
    mutate: addToHotListMutate,
  } = useMutation<any, Error, string[]>(addToHotList, {
    onSuccess: () => {
      reset();
      toast.success("Game added to hot list");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  /* Remove from hotlist mutation 🗑️ 🌐*/
  const {
    isLoading: loadingRemoveFromHotList,
    error: errorRemoveFromHotList,
    mutate: removeFromHotListMutate,
  } = useMutation<any, Error, string[]>(removeFromHotList, {
    onSuccess: () => {
      reset();
      toast.success("Game removed from hot list");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  let isActionLoading = loadingAddToHotList || loadingRemoveFromHotList;
  let actionError =
    errorAddToHotList?.message || errorRemoveFromHotList?.message;

  const columns: GridColDef[] = [
    {
      field: "gameName",
      headerName: "Game Name",
      editable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      editable: true,
      minWidth: 120,
    },
    {
      field: "action",
      renderCell: (cellValues) => {
        return (
          <Stack direction={"row"} gap={2}>
            {tab !== "hot" && (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={!!selectedRows.length || isActionLoading}
                onClick={(event) => {
                  // perform add to hotlist
                  addToHotListMutate([cellValues.id as string]);
                }}
              >
                Add to Hot list
              </Button>
            )}

            <Button
              variant="outlined"
              color="primary"
              size="medium"
              disabled={!!selectedRows.length || isActionLoading}
              onClick={(event) => {
                // perform remove from hotlist
                removeFromHotListMutate([cellValues.id as string]);
              }}
            >
              Remove from Hot list
            </Button>
          </Stack>
        );
      },
      minWidth: 330,
      flex: 1,
    },
  ];

  return (
    <div>
      {(loadingAddToHotList || loadingRemoveFromHotList) && <CustomBackdrop />}
      {actionError && <Alert severity="error">{actionError}</Alert>}
      <SectionTitle.Default title="Manage Games">
        <div className="flex gap-4 flex-wrap w-full items-center justify-between my-6">
          <div>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="All Games" value={"all"} />
              <Tab label="Hot Lists" value={"hot"} />
            </Tabs>
          </div>
          <div className="flex items-center gap-4">
            <FormControl>
              <p className="text-xs text-gray-200">Category</p>
              <Select
                value={category}
                label="category"
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value={"none"}>All</MenuItem>
                {categories.map((cat) => (
                  <MenuItem value={cat} key={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              <p className="text-xs text-gray-200">Search</p>
              <TextField
                onChange={setSearchValue}
                // disabled={isLoading}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <IconButton>
                      <BiSearch />
                    </IconButton>
                  ),
                }}
                placeholder="Search"
                value={searchValue}
              />
            </div>
          </div>
        </div>
      </SectionTitle.Default>

      {/* 
        render when multiple item selected
      */}
      {selectedRows.length > 0 && (
        <MuiltipleGameAction
          ids={selectedRows}
          onAdd={addToHotListMutate}
          onRemove={removeFromHotListMutate}
          tab={tab}
        />
      )}

      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            setSelectedRows([...(ids as string[])]);
          }}
          loading={isLoading || isRefetching}
          error={error?.message}
        />
      </div>
    </div>
  );
};

export default GameManagement;

interface IMuiltipleGameActionProps {
  ids: string[];
  onAdd: (ids: string[]) => void;
  onRemove: (ids: string[]) => void;
  isLoading?: boolean;
  tab: string;
}
const MuiltipleGameAction = memo(
  ({ ids, onAdd, onRemove, tab }: IMuiltipleGameActionProps) => {
    return (
      <div className="p-4 bg-primary rounded-lg">
        <Stack direction={"row"} gap={2} justifyContent="end">
          {tab !== "hot" && (
            <Button
              variant="contained"
              color="inherit"
              size="medium"
              onClick={(event) => {
                // perform add to hotlist

                onAdd(ids);
              }}
            >
              Add to Hot list
            </Button>
          )}

          <Button
            variant="text"
            color="inherit"
            size="medium"
            onClick={(event) => {
              // perform add to hotlist
              onRemove(ids);
            }}
          >
            Remove from Hot list
          </Button>
        </Stack>
      </div>
    );
  }
);
