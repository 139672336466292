import { Button } from "@mui/material";
import pageNotFoundImage from "assets/404.png";
import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const PageNotFound: React.FC<Props> = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <img
        className="inline-block"
        src={pageNotFoundImage}
        alt="Gamin coming soon"
      />
      <h5 className="my-16 text-2xl font-bold">
        Oops! Looks like this page doesn't exist
      </h5>
      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ px: 10 }}
        component={Link}
        to="/"
      >
        Back To Home
      </Button>
    </div>
  );
};

export default PageNotFound;
