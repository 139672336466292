import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { useLogin } from "queries/auth";
import React, { FC, useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { InputStyles } from "styles/form";
import * as yup from "yup";

const schema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
});

export interface ILoginInputs {
  username: string;
  password: string;
}

interface Props {}

const LoginForm: FC<Props> = () => {
  const { loginFinish } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginInputs>({
    resolver: yupResolver(schema),
  });

  const loginMutation = useLogin({
    onSuccess: loginFinish,
    keepLogedin: false,
  });

  const [showPass, setShowPass] = useState(false);

  const onSubmit: SubmitHandler<ILoginInputs> = (data: ILoginInputs) => {
    const payload = {
      username: data.username,
      password: data.password,
    };
    loginMutation.mutate(payload);
  };

  return (
    <form
      className="flex flex-col gap-3 lg:gap-6 w-full select-none"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="username"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={InputStyles.secondary}
            fullWidth
            placeholder="Username"
            {...field}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
        )}
      />
      <Controller
        name="password"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <TextField
            sx={InputStyles.secondary}
            type={showPass ? "text" : "password"}
            variant="outlined"
            fullWidth
            placeholder="Password"
            {...field}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPass ? (
                    <AiFillEye
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      {/* <div className="flex items-center justify-between">
        <Link to={RouteLinks.FORGOT_PASSWORD.link}>
          <p className="text-primary font-semibold text-sm cursor-pointer">
            Forgot Password?
          </p>
        </Link>
      </div> */}

      <Button
        variant="contained"
        disableElevation
        color="primary"
        fullWidth
        size="large"
        type="submit"
        disabled={loginMutation.isLoading}
      >
        {loginMutation.isLoading ? <CircularProgress size={24} /> : "Sign in"}
      </Button>
      {loginMutation.error && (
        <Alert severity="error">{loginMutation.error?.message}</Alert>
      )}

      {/* <p className="text-center text-gray font-bold text-sm">
        Don’t have an account?{" "}
        <a
          className="text-primary"
          target={"_blank"}
          href={`${landingAppUrl}/signup`}
          rel="noreferrer"
        >
          Sign up
        </a>
      </p> */}
    </form>
  );
};

export default LoginForm;
